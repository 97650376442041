import React, { FunctionComponent, useContext } from 'react';
import { Form } from 'react-bootstrap';
import useSWR, { mutate } from 'swr';
import appContext from '../appContext';
import { showErrorModal } from './shared/ErrorModal';

const Category: FunctionComponent = (props) => {
  const { apiClient } = useContext(appContext);
  const { data: categories = [] } = useSWR('categories', () =>
    apiClient.categoriesApi.getAll()
  );

  const handleChangeStatus = (id: string) => {
    apiClient.categoriesApi
      .updateCategoryStatus(id)
      .then(() => {
        mutate('categories');
      })
      .catch(showErrorModal);
  };

  return (
    <div className="container-fluid h-100 overflow-auto">
      <div className="row align-items-center mb-2 border-bottom pb-1">
        <div className="col-9">Категория</div>
        <div className="col">Статус</div>
      </div>
      {categories.map((item) => (
        <div
          className="row align-items-center mb-2 border-bottom pb-1"
          key={item.id}
        >
          <div className="col-9">{item.name}</div>
          <div className="col">
            <Form.Check
              type="checkbox"
              label="Активна"
              checked={item.active}
              onChange={() => handleChangeStatus(item.id)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Category;
