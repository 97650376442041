/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Точка входа в приложение
 */

import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.scss';
import * as serviceWorker from './serviceWorker';
import getEnv, { str, EnvError } from 'cra-env-settings';
import App from './App';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import reportWebVitals from './reportWebVitals';
import { s3Url } from './utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Env {
  REACT_APP_SENTRY_DSN: string;
  REACT_APP_IMG_PREFIX: string;
}

/**
 * Получение настроек, врежиме разработки из process.env,
 * в продакшен режиме из window.env
 */
const env = getEnv<Env>({
  REACT_APP_SENTRY_DSN: str(),
  REACT_APP_IMG_PREFIX: str(),
});

if (env instanceof EnvError) {
  ReactDOM.render(
    <div>
      Ошибка загрузки переменных окружения!
      <p>{env.message}</p>
      <pre>{JSON.stringify(env.errors, null, 2)}</pre>
    </div>,
    document.getElementById('root')
  );
} else {
  if (process.env.REACT_APP_ENABLE_SENTRY) {
    Sentry.init({
      dsn: env.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }
  ReactDOM.render(
    <App getS3Url={s3Url(env.REACT_APP_IMG_PREFIX)} />,
    document.getElementById('root')
  );
}

serviceWorker.unregister();

/**
 * Получение токена Forge и запуск приложения
 * @param env настройки приложения
 */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
