import { FileLoadType } from '../types';

export function sanitizeUrl(url: string): string {
  return '/' + url.replace(/^\/*/, '');
}

export const loadFileAsB64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((reader.result as string).split(',')[1]);
    reader.onerror = (error) => reject(error);
  });
};

export const s3Url = (prefix: string) => (directory: 'img', url: string) => {
  return `/${directory}/${prefix}/${url}`;
};
export type GetS3Url = ReturnType<typeof s3Url>;

export function getFileName(path: string) {
  return path.split('\\').pop();
}

export const loadFile = (
  file: File | Blob,
  loadType: FileLoadType
): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const data = reader.result;
      resolve(data);
    };
    reader.onerror = () => {
      reader.abort();
      reject(new Error('File loading error'));
    };

    reader[loadType](file);
  });

const imageTypes = [
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
  'image/x-icon',
];
export const isImage = (file: File): boolean => imageTypes.includes(file.type);

export const createFillArray = <T>(
  length: number,
  fillCb: (i: number) => T
): T[] =>
  Array(length)
    .fill(0)
    .map((x: any, i) => (x === 0 ? fillCb(i) : fillCb(i)));

export const base64ImgToDataUrl = (imgName: string, base64Img: string) => {
  const imgExtension = imgName.split('.').pop();
  return `data:image/${imgExtension};base64,${base64Img}`;
};
