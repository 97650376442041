import React, { FunctionComponent } from 'react';
import Logo from '../../assets/svg/logo';
import SignOut from '../../assets/svg/SignOut';

interface Props {
  dummy?: any;
}

const Header: FunctionComponent<Props> = (props) => {
  const signOut = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };

  return (
    <nav className="navbar navbar-dark bg-dark text-white">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <Logo height="30px" />
        </a>
        <div>
          &quot;Цифровой каталог&quot; {process.env.REACT_APP_VERSION}{' '}
          {process.env.REACT_APP_DATE}
        </div>
        <SignOut height="30px" onClick={signOut} />
      </div>
    </nav>
  );
};

export default Header;
