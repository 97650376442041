import React, { FunctionComponent, useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import appContext from '../appContext';
import { showErrorModal } from './shared/ErrorModal';

const SeedData: FunctionComponent = () => {
  const { apiClient } = useContext(appContext);

  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    if (!window.confirm('Данные из таблиц будут удалены!')) return;
    setLoading(true);
    apiClient.seederApi
      .seedData()
      .catch(showErrorModal)
      .finally(() => setLoading(false));
  };

  return (
    <div className="container-fluid h-100 overflow-auto d-flex justify-content-center align-items-center">
      <Button disabled={loading} onClick={handleClick}>
        Сгенерировать данные
      </Button>
      {loading && <Spinner className="ml-5" animation="grow" />}
    </div>
  );
};

export default SeedData;
