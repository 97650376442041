import React from 'react';
import ReactDOM from 'react-dom';
import Modal from './Modal/Modal';
import { ApiClientError } from '../../api/ApiClient';
import { Button } from 'react-bootstrap';

interface IAppError {
  title: string;
  statusMessage: string;
  url: string;
  description: string;
  exception: string;
}

interface IProps {
  error: IAppError;
  onClose: () => void;
}

export const ErrorModal: React.FC<IProps> = ({ error, onClose }) => {
  const renderError = React.useCallback(
    () => (
      <>
        {error.url.length > 0 && renderRow('Url', error.url)}
        {error.statusMessage.length > 0 &&
          renderRow('Результат', error.statusMessage)}
        {renderRow('Описание', error.description)}
        {error.exception.length > 0 && renderRow('Exception', error.exception)}
      </>
    ),
    [error]
  );
  const title = React.useMemo(
    () => (error.title ? `Ошибка: ${error.title.toLowerCase()}` : 'Ошибка!'),
    [error.title]
  );
  return (
    <Modal size="modal-fullscreen" title={title} onClose={onClose}>
      <div className="w-full d-flex flex-wrap justify-content-between py-1 mb-2">
        <Button color="accent" onClick={() => window.location.reload()}>
          Перезагрузить
        </Button>
        <Button color="accent" onClick={() => (window.location.href = '/')}>
          На главную
        </Button>
        <Button color="accent" onClick={onClose}>
          Закрыть
        </Button>
      </div>
      {renderError()}
    </Modal>
  );
};

export const showErrorModal = async (
  errorData: Error | Response | string,
  action = ''
) => {
  console.error(action, errorData);
  const error: IAppError = {
    description: '',
    exception: '',
    title: action,
    url: '',
    statusMessage: '',
  };
  if (typeof errorData === 'object' && (errorData as any).status) {
    const err = errorData as Response;
    const body = await err.json();
    const apiError = new ApiClientError(err, body);
    error.statusMessage = err.statusText;
    error.description = apiError.statusMessage ?? '';
    error.url = err.url ?? '';
  } else if (errorData instanceof Error) {
    error.description = errorData.message ?? '';
    error.exception = errorData.stack ?? '';
  } else {
    error.description = errorData as string;
  }

  const modal = document.createElement('div');
  document.body.appendChild(modal);
  ReactDOM.render(
    <ErrorModal
      error={error}
      onClose={() => {
        ReactDOM.unmountComponentAtNode(modal);
        document.body.removeChild(modal);
      }}
    />,
    modal
  );
};

function renderRow(title: string, content: string) {
  return (
    <div className="mb-2">
      <h6 className="fs-3 m-0">{title}:</h6>
      <code
        className="d-block bg-light text-wrap p-3"
        style={{ maxHeight: '50vh', overflow: 'auto' }}
      >
        {content}
      </code>
    </div>
  );
}
