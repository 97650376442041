import React, { FunctionComponent, useState } from 'react';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import Category from './Category';
import Content from './Content';
import Info from './Info';
import Manufacturer from './Manufacturer';
import ProductList from './ProductList';
import SeedData from './SeedData';

const Navigation: FunctionComponent = (props) => {
  const [key, setKey] = useState('Products');

  return (
    <div className="w-100 h-100 bg-light">
      <Tab.Container
        defaultActiveKey="Products"
        activeKey={key}
        onSelect={(k) => setKey(k ?? 'Products')}
      >
        <Row className="h-100">
          <Col sm={3} className="border-end border-3 h-100 p-2">
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="Products">Продукты</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Manufacturer">Производители</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Category">Категории</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Contacts">Контакты</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Content">Контент</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="Seeder">Seed data</Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Col>
          <Col sm={9} className="h-100">
            <Tab.Content className="h-100">
              <Tab.Pane eventKey="Products" className="h-100">
                <ProductList />
              </Tab.Pane>
              <Tab.Pane eventKey="Manufacturer" className="h-100">
                <Manufacturer />
              </Tab.Pane>
              <Tab.Pane eventKey="Category" className="h-100">
                <Category />
              </Tab.Pane>
              <Tab.Pane eventKey="Contacts" className="h-100">
                <Info />
              </Tab.Pane>
              <Tab.Pane eventKey="Seeder" className="h-100">
                <SeedData />
              </Tab.Pane>
              <Tab.Pane eventKey="Content" className="h-100">
                <Content />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default Navigation;
