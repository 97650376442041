import React from 'react';
import { ApiClient } from './api/ApiClient';
import { GetS3Url } from './utils';

export interface AppContext {
  apiClient: ApiClient;
  getS3Url: GetS3Url;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const appContext = React.createContext<AppContext>({} as any);
export default appContext;
