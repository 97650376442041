import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { Card, Form, Button } from 'react-bootstrap';
import useSWR, { mutate } from 'swr';

import { UpdateContentDto, UploadImageDto } from '../api/generated';
import appContext from '../appContext';
import { TextFileLoadType } from '../types';
import { base64ImgToDataUrl } from '../utils';
import { showErrorModal } from './shared/ErrorModal';
import { showFileInput } from './shared/FileInput/FileInput';

const Content: FunctionComponent = () => {
  const { apiClient, getS3Url } = useContext(appContext);
  const [newContent, setNewContent] = useState<UpdateContentDto>({
    about: '',
    payment: '',
    deliveryBelarusKazakhstan: '',
    deliveryRussia: '',
  });

  const { data: content } = useSWR('content', () =>
    apiClient.contentApi.getContent()
  );

  useEffect(
    function updateInfo() {
      if (content) {
        setNewContent(content);
      }
    },
    [content]
  );

  const handleChangeInput = (fieldName: keyof UpdateContentDto) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.currentTarget;
    setNewContent((x) => ({ ...x, [fieldName]: value }));
  };

  const handleSubmitForm = () => {
    apiClient.contentApi
      .update(newContent)
      .then(() => mutate('content'))
      .then(() => toast('Данные успешно обновлены', { type: 'success' }))
      .catch(showErrorModal);
  };

  const handleAddImage = () => {
    showFileInput<TextFileLoadType>({
      accept: 'image/*',
      onLoadFiles: (result) => {
        const newImage: UploadImageDto = {
          name: result[0].fileName,
          base64Image: btoa(result[0].data),
        };
        setNewContent((x) => ({ ...x, newImage }));
      },
      multiple: false,
      loadType: 'readAsBinaryString',
    });
  };

  const renderTextArea = (
    fieldName: keyof UpdateContentDto,
    value: string,
    label?: string
  ) => (
    <Form.Group className="mb-3">
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        value={value}
        onChange={handleChangeInput(fieldName)}
        rows={6}
        as="textarea"
      />
    </Form.Group>
  );

  const renderImg = (src?: string) => {
    if (!src) return null;
    return (
      <Card.Img
        width="300px"
        height="200px"
        className="object-fit_contain p-2"
        variant="top"
        src={src}
      />
    );
  };

  return (
    <div className="container-fluid h-100 overflow-auto p-2">
      <Card>
        {renderImg(
          newContent.newImage
            ? base64ImgToDataUrl(
                newContent.newImage.name,
                newContent.newImage.base64Image
              )
            : content?.aboutImageUrl && getS3Url('img', content.aboutImageUrl)
        )}

        <Card.Body>
          <Card.Title className="mb-3">О компании</Card.Title>
          <Form className="mb-5">
            {renderTextArea('about', newContent.about)}
            <Button
              className="me-2"
              variant="outline-primary"
              onClick={handleAddImage}
            >
              <span>Загрузить фото</span>

              <i className="far fa-file-image ms-2" />
            </Button>
          </Form>
        </Card.Body>
        <Card.Body>
          <Card.Title className="mb-3">Доставка и оплата</Card.Title>
          <Form className="mb-5">
            {renderTextArea('payment', newContent.payment, 'Оплата')}
            {renderTextArea(
              'deliveryRussia',
              newContent.deliveryRussia,
              'Доставка по России'
            )}
            {renderTextArea(
              'deliveryBelarusKazakhstan',
              newContent.deliveryBelarusKazakhstan,
              'Доставка в Беларуссию и Казахстан'
            )}
          </Form>
        </Card.Body>
        <Card.Body>
          <Card.Title className="mb-3">Портфолио</Card.Title>
          <Form className="mb-5">
            {renderTextArea(
              'portfolioUrl',
              newContent?.portfolioUrl ?? '',
              'Ссылка'
            )}
          </Form>
          <Button onClick={handleSubmitForm}>Обновить</Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Content;
