/* tslint:disable */
/* eslint-disable */


import * as url from "url";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration?: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name!: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AboutPageDto
 */
export interface AboutPageDto {
    /**
     * 
     * @type {string}
     * @memberof AboutPageDto
     */
    about: string;
    /**
     * 
     * @type {string}
     * @memberof AboutPageDto
     */
    aboutImageUrl: string;
}
/**
 * 
 * @export
 * @interface AddManufacturerToProductDto
 */
export interface AddManufacturerToProductDto {
    /**
     * 
     * @type {string}
     * @memberof AddManufacturerToProductDto
     */
    productId: string;
    /**
     * 
     * @type {ManufacturerDto}
     * @memberof AddManufacturerToProductDto
     */
    manufacturer: ManufacturerDto;
}
/**
 * 
 * @export
 * @interface CategoryDto
 */
export interface CategoryDto {
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryDto
     */
    active: boolean;
}
/**
 * 
 * @export
 * @interface ContentDto
 */
export interface ContentDto {
    /**
     * 
     * @type {string}
     * @memberof ContentDto
     */
    about: string;
    /**
     * 
     * @type {string}
     * @memberof ContentDto
     */
    aboutImageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ContentDto
     */
    payment: string;
    /**
     * 
     * @type {string}
     * @memberof ContentDto
     */
    deliveryRussia: string;
    /**
     * 
     * @type {string}
     * @memberof ContentDto
     */
    deliveryBelarusKazakhstan: string;
    /**
     * 
     * @type {string}
     * @memberof ContentDto
     */
    portfolioUrl: string;
}
/**
 * 
 * @export
 * @interface CreateCategoryDto
 */
export interface CreateCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CreateEmailDto
 */
export interface CreateEmailDto {
    /**
     * 
     * @type {string}
     * @memberof CreateEmailDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmailDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmailDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmailDto
     */
    product?: string;
}
/**
 * 
 * @export
 * @interface CreateManufacturerDto
 */
export interface CreateManufacturerDto {
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateProductDto
 */
export interface CreateOrUpdateProductDto {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateProductDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateProductDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateProductDto
     */
    code?: string;
    /**
     * 
     * @type {CreateCategoryDto}
     * @memberof CreateOrUpdateProductDto
     */
    category: CreateCategoryDto;
    /**
     * 
     * @type {CreateManufacturerDto}
     * @memberof CreateOrUpdateProductDto
     */
    manufacturer?: CreateManufacturerDto;
}
/**
 * 
 * @export
 * @interface CreateOrUpdatePropertyDto
 */
export interface CreateOrUpdatePropertyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdatePropertyDto
     */
    groupId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdatePropertyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdatePropertyDto
     */
    value: string;
}
/**
 * 
 * @export
 * @interface DeliveryPageDto
 */
export interface DeliveryPageDto {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPageDto
     */
    payment: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPageDto
     */
    deliveryRussia: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPageDto
     */
    deliveryBelarusKazakhstan: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPageDto
     */
    portfolioUrl: string;
}
/**
 * 
 * @export
 * @interface InfoDto
 */
export interface InfoDto {
    /**
     * 
     * @type {string}
     * @memberof InfoDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof InfoDto
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    password: string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    accessToken: string;
}
/**
 * 
 * @export
 * @interface ManufacturerDto
 */
export interface ManufacturerDto {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManufacturerDto
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDto
     */
    imageUrl?: string;
}
/**
 * 
 * @export
 * @interface ManufacturerWithImageDto
 */
export interface ManufacturerWithImageDto {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerWithImageDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerWithImageDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerWithImageDto
     */
    imageUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManufacturerWithImageDto
     */
    isActive: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedProductDto
 */
export interface PaginatedProductDto {
    /**
     * 
     * @type {number}
     * @memberof PaginatedProductDto
     */
    total: number;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof PaginatedProductDto
     */
    results: Array<ProductDto>;
}
/**
 * 
 * @export
 * @interface PortfolioDto
 */
export interface PortfolioDto {
    /**
     * 
     * @type {string}
     * @memberof PortfolioDto
     */
    portfolioUrl: string;
}
/**
 * 
 * @export
 * @interface ProductCategoryDataDto
 */
export interface ProductCategoryDataDto {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryDataDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryDataDto
     */
    name: string;
    /**
     * 
     * @type {Array<ProductCategoryPropertyDto>}
     * @memberof ProductCategoryDataDto
     */
    groupedProperties: Array<ProductCategoryPropertyDto>;
    /**
     * 
     * @type {Array<ManufacturerDto>}
     * @memberof ProductCategoryDataDto
     */
    manufacturers: Array<ManufacturerDto>;
}
/**
 * 
 * @export
 * @interface ProductCategoryPropertyDto
 */
export interface ProductCategoryPropertyDto {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryPropertyDto
     */
    groupName: string;
    /**
     * 
     * @type {Array<PropertyDto>}
     * @memberof ProductCategoryPropertyDto
     */
    properties: Array<PropertyDto>;
}
/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    slug: string;
}
/**
 * 
 * @export
 * @interface ProductWithDataDto
 */
export interface ProductWithDataDto {
    /**
     * 
     * @type {string}
     * @memberof ProductWithDataDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductWithDataDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductWithDataDto
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductWithDataDto
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ProductWithDataDto
     */
    imageUrl: string;
    /**
     * 
     * @type {ManufacturerDto}
     * @memberof ProductWithDataDto
     */
    manufacturer: ManufacturerDto;
    /**
     * 
     * @type {CategoryDto}
     * @memberof ProductWithDataDto
     */
    category: CategoryDto;
    /**
     * 
     * @type {Array<PropertyDto>}
     * @memberof ProductWithDataDto
     */
    properties: Array<PropertyDto>;
}
/**
 * 
 * @export
 * @interface PropertyDto
 */
export interface PropertyDto {
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    groupId: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    value: string;
}
/**
 * 
 * @export
 * @interface UpdateContentDto
 */
export interface UpdateContentDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateContentDto
     */
    about: string;
    /**
     * 
     * @type {UploadImageDto}
     * @memberof UpdateContentDto
     */
    newImage?: UploadImageDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateContentDto
     */
    payment: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContentDto
     */
    deliveryRussia: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContentDto
     */
    deliveryBelarusKazakhstan: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContentDto
     */
    portfolioUrl?: string;
}
/**
 * 
 * @export
 * @interface UpdateInfoDto
 */
export interface UpdateInfoDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateInfoDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInfoDto
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface UploadImageDto
 */
export interface UploadImageDto {
    /**
     * 
     * @type {string}
     * @memberof UploadImageDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UploadImageDto
     */
    base64Image: string;
}
/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGetProfile(options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/profile`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary login
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(body: LoginRequest, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerLogin.');
            }
            const localVarPath = `/api/auth/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LoginRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGetProfile(options?: any): Promise<(basePath?: string) => Promise<any>> {
            const localVarFetchArgs = await AuthApiFetchParamCreator(configuration).authControllerGetProfile(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary login
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(body: LoginRequest, options?: any): Promise<(basePath?: string) => Promise<LoginResponse>> {
            const localVarFetchArgs = await AuthApiFetchParamCreator(configuration).authControllerLogin(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary getProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGetProfile(options?: any) {
            return (await AuthApiFp(configuration).authControllerGetProfile(options))(basePath);
        },
        /**
         * 
         * @summary login
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(body: LoginRequest, options?: any) {
            return (await AuthApiFp(configuration).authControllerLogin(body, options))(basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary getProfile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public async getProfile(options?: any) {
        return (await AuthApiFp(this.configuration).authControllerGetProfile(options))(this.basePath);
    }

    /**
     * 
     * @summary login
     * @param {LoginRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public async login(body: LoginRequest, options?: any) {
        return (await AuthApiFp(this.configuration).authControllerLogin(body, options))(this.basePath);
    }

}
/**
 * CategoriesApi - fetch parameter creator
 * @export
 */
export const CategoriesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createOrUpdateCategories
         * @param {Array<CategoryDto>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerCreateOrUpdateCategories(body: Array<CategoryDto>, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling categoryControllerCreateOrUpdateCategories.');
            }
            const localVarPath = `/api/categories/create-or-update/many`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;CategoryDto&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createOrUpdateCategory
         * @param {CategoryDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerCreateOrUpdateCategory(body: CategoryDto, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling categoryControllerCreateOrUpdateCategory.');
            }
            const localVarPath = `/api/categories/create-or-update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CategoryDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getActive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerGetActive(options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/categories/active`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerGetAll(options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCategoryById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerGetCategoryById(id: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling categoryControllerGetCategoryById.');
            }
            const localVarPath = `/api/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCategoryProducts
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerGetCategoryProducts(id: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling categoryControllerGetCategoryProducts.');
            }
            const localVarPath = `/api/categories/{id}/products`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateCategoryStatus
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerUpdateCategoryStatus(id: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling categoryControllerUpdateCategoryStatus.');
            }
            const localVarPath = `/api/categories/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createOrUpdateCategories
         * @param {Array<CategoryDto>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerCreateOrUpdateCategories(body: Array<CategoryDto>, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await CategoriesApiFetchParamCreator(configuration).categoryControllerCreateOrUpdateCategories(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createOrUpdateCategory
         * @param {CategoryDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerCreateOrUpdateCategory(body: CategoryDto, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await CategoriesApiFetchParamCreator(configuration).categoryControllerCreateOrUpdateCategory(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getActive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerGetActive(options?: any): Promise<(basePath?: string) => Promise<Array<CategoryDto>>> {
            const localVarFetchArgs = await CategoriesApiFetchParamCreator(configuration).categoryControllerGetActive(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerGetAll(options?: any): Promise<(basePath?: string) => Promise<Array<CategoryDto>>> {
            const localVarFetchArgs = await CategoriesApiFetchParamCreator(configuration).categoryControllerGetAll(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCategoryById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerGetCategoryById(id: string, options?: any): Promise<(basePath?: string) => Promise<CategoryDto>> {
            const localVarFetchArgs = await CategoriesApiFetchParamCreator(configuration).categoryControllerGetCategoryById(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCategoryProducts
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerGetCategoryProducts(id: string, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await CategoriesApiFetchParamCreator(configuration).categoryControllerGetCategoryProducts(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateCategoryStatus
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerUpdateCategoryStatus(id: string, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await CategoriesApiFetchParamCreator(configuration).categoryControllerUpdateCategoryStatus(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary createOrUpdateCategories
         * @param {Array<CategoryDto>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerCreateOrUpdateCategories(body: Array<CategoryDto>, options?: any) {
            return (await CategoriesApiFp(configuration).categoryControllerCreateOrUpdateCategories(body, options))(basePath);
        },
        /**
         * 
         * @summary createOrUpdateCategory
         * @param {CategoryDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerCreateOrUpdateCategory(body: CategoryDto, options?: any) {
            return (await CategoriesApiFp(configuration).categoryControllerCreateOrUpdateCategory(body, options))(basePath);
        },
        /**
         * 
         * @summary getActive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerGetActive(options?: any) {
            return (await CategoriesApiFp(configuration).categoryControllerGetActive(options))(basePath);
        },
        /**
         * 
         * @summary getAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerGetAll(options?: any) {
            return (await CategoriesApiFp(configuration).categoryControllerGetAll(options))(basePath);
        },
        /**
         * 
         * @summary getCategoryById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerGetCategoryById(id: string, options?: any) {
            return (await CategoriesApiFp(configuration).categoryControllerGetCategoryById(id, options))(basePath);
        },
        /**
         * 
         * @summary getCategoryProducts
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerGetCategoryProducts(id: string, options?: any) {
            return (await CategoriesApiFp(configuration).categoryControllerGetCategoryProducts(id, options))(basePath);
        },
        /**
         * 
         * @summary updateCategoryStatus
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerUpdateCategoryStatus(id: string, options?: any) {
            return (await CategoriesApiFp(configuration).categoryControllerUpdateCategoryStatus(id, options))(basePath);
        },
    };
};

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI {
    /**
     * 
     * @summary createOrUpdateCategories
     * @param {Array<CategoryDto>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public async createOrUpdateCategories(body: Array<CategoryDto>, options?: any) {
        return (await CategoriesApiFp(this.configuration).categoryControllerCreateOrUpdateCategories(body, options))(this.basePath);
    }

    /**
     * 
     * @summary createOrUpdateCategory
     * @param {CategoryDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public async createOrUpdateCategory(body: CategoryDto, options?: any) {
        return (await CategoriesApiFp(this.configuration).categoryControllerCreateOrUpdateCategory(body, options))(this.basePath);
    }

    /**
     * 
     * @summary getActive
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public async getActive(options?: any) {
        return (await CategoriesApiFp(this.configuration).categoryControllerGetActive(options))(this.basePath);
    }

    /**
     * 
     * @summary getAll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public async getAll(options?: any) {
        return (await CategoriesApiFp(this.configuration).categoryControllerGetAll(options))(this.basePath);
    }

    /**
     * 
     * @summary getCategoryById
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public async getCategoryById(id: string, options?: any) {
        return (await CategoriesApiFp(this.configuration).categoryControllerGetCategoryById(id, options))(this.basePath);
    }

    /**
     * 
     * @summary getCategoryProducts
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public async getCategoryProducts(id: string, options?: any) {
        return (await CategoriesApiFp(this.configuration).categoryControllerGetCategoryProducts(id, options))(this.basePath);
    }

    /**
     * 
     * @summary updateCategoryStatus
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public async updateCategoryStatus(id: string, options?: any) {
        return (await CategoriesApiFp(this.configuration).categoryControllerUpdateCategoryStatus(id, options))(this.basePath);
    }

}
/**
 * ContentApi - fetch parameter creator
 * @export
 */
export const ContentApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAboutContent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerGetAboutContent(options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/content/about`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getContent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerGetContent(options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/content`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDeliveryContent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerGetDeliveryContent(options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/content/delivery`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPortfolioContent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerGetPortfolioContent(options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/content/portfolio`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update
         * @param {UpdateContentDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerUpdate(body: UpdateContentDto, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling contentControllerUpdate.');
            }
            const localVarPath = `/api/content`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateContentDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentApi - functional programming interface
 * @export
 */
export const ContentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAboutContent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerGetAboutContent(options?: any): Promise<(basePath?: string) => Promise<AboutPageDto>> {
            const localVarFetchArgs = await ContentApiFetchParamCreator(configuration).contentControllerGetAboutContent(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getContent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerGetContent(options?: any): Promise<(basePath?: string) => Promise<ContentDto>> {
            const localVarFetchArgs = await ContentApiFetchParamCreator(configuration).contentControllerGetContent(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getDeliveryContent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerGetDeliveryContent(options?: any): Promise<(basePath?: string) => Promise<DeliveryPageDto>> {
            const localVarFetchArgs = await ContentApiFetchParamCreator(configuration).contentControllerGetDeliveryContent(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getPortfolioContent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerGetPortfolioContent(options?: any): Promise<(basePath?: string) => Promise<PortfolioDto>> {
            const localVarFetchArgs = await ContentApiFetchParamCreator(configuration).contentControllerGetPortfolioContent(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary update
         * @param {UpdateContentDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerUpdate(body: UpdateContentDto, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await ContentApiFetchParamCreator(configuration).contentControllerUpdate(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ContentApi - factory interface
 * @export
 */
export const ContentApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary getAboutContent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerGetAboutContent(options?: any) {
            return (await ContentApiFp(configuration).contentControllerGetAboutContent(options))(basePath);
        },
        /**
         * 
         * @summary getContent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerGetContent(options?: any) {
            return (await ContentApiFp(configuration).contentControllerGetContent(options))(basePath);
        },
        /**
         * 
         * @summary getDeliveryContent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerGetDeliveryContent(options?: any) {
            return (await ContentApiFp(configuration).contentControllerGetDeliveryContent(options))(basePath);
        },
        /**
         * 
         * @summary getPortfolioContent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerGetPortfolioContent(options?: any) {
            return (await ContentApiFp(configuration).contentControllerGetPortfolioContent(options))(basePath);
        },
        /**
         * 
         * @summary update
         * @param {UpdateContentDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentControllerUpdate(body: UpdateContentDto, options?: any) {
            return (await ContentApiFp(configuration).contentControllerUpdate(body, options))(basePath);
        },
    };
};

/**
 * ContentApi - object-oriented interface
 * @export
 * @class ContentApi
 * @extends {BaseAPI}
 */
export class ContentApi extends BaseAPI {
    /**
     * 
     * @summary getAboutContent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public async getAboutContent(options?: any) {
        return (await ContentApiFp(this.configuration).contentControllerGetAboutContent(options))(this.basePath);
    }

    /**
     * 
     * @summary getContent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public async getContent(options?: any) {
        return (await ContentApiFp(this.configuration).contentControllerGetContent(options))(this.basePath);
    }

    /**
     * 
     * @summary getDeliveryContent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public async getDeliveryContent(options?: any) {
        return (await ContentApiFp(this.configuration).contentControllerGetDeliveryContent(options))(this.basePath);
    }

    /**
     * 
     * @summary getPortfolioContent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public async getPortfolioContent(options?: any) {
        return (await ContentApiFp(this.configuration).contentControllerGetPortfolioContent(options))(this.basePath);
    }

    /**
     * 
     * @summary update
     * @param {UpdateContentDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public async update(body: UpdateContentDto, options?: any) {
        return (await ContentApiFp(this.configuration).contentControllerUpdate(body, options))(this.basePath);
    }

}
/**
 * EmailApi - fetch parameter creator
 * @export
 */
export const EmailApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary send
         * @param {CreateEmailDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailControllerSend(body: CreateEmailDto, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling emailControllerSend.');
            }
            const localVarPath = `/api/email`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateEmailDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary send
         * @param {CreateEmailDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailControllerSend(body: CreateEmailDto, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await EmailApiFetchParamCreator(configuration).emailControllerSend(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary send
         * @param {CreateEmailDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailControllerSend(body: CreateEmailDto, options?: any) {
            return (await EmailApiFp(configuration).emailControllerSend(body, options))(basePath);
        },
    };
};

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
    /**
     * 
     * @summary send
     * @param {CreateEmailDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public async send(body: CreateEmailDto, options?: any) {
        return (await EmailApiFp(this.configuration).emailControllerSend(body, options))(this.basePath);
    }

}
/**
 * InfoApi - fetch parameter creator
 * @export
 */
export const InfoApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoControllerGet(options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/info`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update
         * @param {UpdateInfoDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoControllerUpdate(body: UpdateInfoDto, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling infoControllerUpdate.');
            }
            const localVarPath = `/api/info`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateInfoDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoApi - functional programming interface
 * @export
 */
export const InfoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoControllerGet(options?: any): Promise<(basePath?: string) => Promise<InfoDto>> {
            const localVarFetchArgs = await InfoApiFetchParamCreator(configuration).infoControllerGet(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary update
         * @param {UpdateInfoDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoControllerUpdate(body: UpdateInfoDto, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await InfoApiFetchParamCreator(configuration).infoControllerUpdate(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * InfoApi - factory interface
 * @export
 */
export const InfoApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoControllerGet(options?: any) {
            return (await InfoApiFp(configuration).infoControllerGet(options))(basePath);
        },
        /**
         * 
         * @summary update
         * @param {UpdateInfoDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoControllerUpdate(body: UpdateInfoDto, options?: any) {
            return (await InfoApiFp(configuration).infoControllerUpdate(body, options))(basePath);
        },
    };
};

/**
 * InfoApi - object-oriented interface
 * @export
 * @class InfoApi
 * @extends {BaseAPI}
 */
export class InfoApi extends BaseAPI {
    /**
     * 
     * @summary get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public async get(options?: any) {
        return (await InfoApiFp(this.configuration).infoControllerGet(options))(this.basePath);
    }

    /**
     * 
     * @summary update
     * @param {UpdateInfoDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public async update(body: UpdateInfoDto, options?: any) {
        return (await InfoApiFp(this.configuration).infoControllerUpdate(body, options))(this.basePath);
    }

}
/**
 * ManufacturersApi - fetch parameter creator
 * @export
 */
export const ManufacturersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addImageToManufacturer
         * @param {UploadImageDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerAddImageToManufacturer(body: UploadImageDto, id: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling manufacturerControllerAddImageToManufacturer.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling manufacturerControllerAddImageToManufacturer.');
            }
            const localVarPath = `/api/manufacturers/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UploadImageDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createOrUpdateManufacturer
         * @param {CreateManufacturerDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerCreateOrUpdateManufacturer(body: CreateManufacturerDto, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling manufacturerControllerCreateOrUpdateManufacturer.');
            }
            const localVarPath = `/api/manufacturers/create-or-update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateManufacturerDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createOrUpdateManufacturers
         * @param {Array<CreateManufacturerDto>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerCreateOrUpdateManufacturers(body: Array<CreateManufacturerDto>, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling manufacturerControllerCreateOrUpdateManufacturers.');
            }
            const localVarPath = `/api/manufacturers/create-or-update/many`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;CreateManufacturerDto&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetAll(options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/manufacturers/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllWithImage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetAllWithImage(options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/manufacturers/with-image`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getManufacturerById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetManufacturerById(id: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling manufacturerControllerGetManufacturerById.');
            }
            const localVarPath = `/api/manufacturers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getManufacturerCategories
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetManufacturerCategories(id: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling manufacturerControllerGetManufacturerCategories.');
            }
            const localVarPath = `/api/manufacturers/{id}/categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getManufacturerGroupedByName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetManufacturerGroupedByName(options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/manufacturers/grouped-by/name`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getManufacturerProducts
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetManufacturerProducts(id: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling manufacturerControllerGetManufacturerProducts.');
            }
            const localVarPath = `/api/manufacturers/{id}/products`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getManufacturers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetManufacturers(options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/manufacturers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary removeImageFromManufacturer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerRemoveImageFromManufacturer(id: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling manufacturerControllerRemoveImageFromManufacturer.');
            }
            const localVarPath = `/api/manufacturers/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateManufacturerStatus
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerUpdateManufacturerStatus(id: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling manufacturerControllerUpdateManufacturerStatus.');
            }
            const localVarPath = `/api/manufacturers/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturersApi - functional programming interface
 * @export
 */
export const ManufacturersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addImageToManufacturer
         * @param {UploadImageDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerAddImageToManufacturer(body: UploadImageDto, id: string, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await ManufacturersApiFetchParamCreator(configuration).manufacturerControllerAddImageToManufacturer(body, id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createOrUpdateManufacturer
         * @param {CreateManufacturerDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerCreateOrUpdateManufacturer(body: CreateManufacturerDto, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await ManufacturersApiFetchParamCreator(configuration).manufacturerControllerCreateOrUpdateManufacturer(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createOrUpdateManufacturers
         * @param {Array<CreateManufacturerDto>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerCreateOrUpdateManufacturers(body: Array<CreateManufacturerDto>, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await ManufacturersApiFetchParamCreator(configuration).manufacturerControllerCreateOrUpdateManufacturers(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetAll(options?: any): Promise<(basePath?: string) => Promise<Array<ManufacturerDto>>> {
            const localVarFetchArgs = await ManufacturersApiFetchParamCreator(configuration).manufacturerControllerGetAll(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllWithImage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetAllWithImage(options?: any): Promise<(basePath?: string) => Promise<Array<ManufacturerWithImageDto>>> {
            const localVarFetchArgs = await ManufacturersApiFetchParamCreator(configuration).manufacturerControllerGetAllWithImage(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getManufacturerById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetManufacturerById(id: string, options?: any): Promise<(basePath?: string) => Promise<ManufacturerDto>> {
            const localVarFetchArgs = await ManufacturersApiFetchParamCreator(configuration).manufacturerControllerGetManufacturerById(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getManufacturerCategories
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetManufacturerCategories(id: string, options?: any): Promise<(basePath?: string) => Promise<Array<CategoryDto>>> {
            const localVarFetchArgs = await ManufacturersApiFetchParamCreator(configuration).manufacturerControllerGetManufacturerCategories(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getManufacturerGroupedByName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetManufacturerGroupedByName(options?: any): Promise<(basePath?: string) => Promise<{ [key: string]: Array<ManufacturerDto>; }>> {
            const localVarFetchArgs = await ManufacturersApiFetchParamCreator(configuration).manufacturerControllerGetManufacturerGroupedByName(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getManufacturerProducts
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetManufacturerProducts(id: string, options?: any): Promise<(basePath?: string) => Promise<Array<ProductDto>>> {
            const localVarFetchArgs = await ManufacturersApiFetchParamCreator(configuration).manufacturerControllerGetManufacturerProducts(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getManufacturers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetManufacturers(options?: any): Promise<(basePath?: string) => Promise<Array<ManufacturerDto>>> {
            const localVarFetchArgs = await ManufacturersApiFetchParamCreator(configuration).manufacturerControllerGetManufacturers(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary removeImageFromManufacturer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerRemoveImageFromManufacturer(id: string, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await ManufacturersApiFetchParamCreator(configuration).manufacturerControllerRemoveImageFromManufacturer(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateManufacturerStatus
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerUpdateManufacturerStatus(id: string, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await ManufacturersApiFetchParamCreator(configuration).manufacturerControllerUpdateManufacturerStatus(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ManufacturersApi - factory interface
 * @export
 */
export const ManufacturersApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary addImageToManufacturer
         * @param {UploadImageDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerAddImageToManufacturer(body: UploadImageDto, id: string, options?: any) {
            return (await ManufacturersApiFp(configuration).manufacturerControllerAddImageToManufacturer(body, id, options))(basePath);
        },
        /**
         * 
         * @summary createOrUpdateManufacturer
         * @param {CreateManufacturerDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerCreateOrUpdateManufacturer(body: CreateManufacturerDto, options?: any) {
            return (await ManufacturersApiFp(configuration).manufacturerControllerCreateOrUpdateManufacturer(body, options))(basePath);
        },
        /**
         * 
         * @summary createOrUpdateManufacturers
         * @param {Array<CreateManufacturerDto>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerCreateOrUpdateManufacturers(body: Array<CreateManufacturerDto>, options?: any) {
            return (await ManufacturersApiFp(configuration).manufacturerControllerCreateOrUpdateManufacturers(body, options))(basePath);
        },
        /**
         * 
         * @summary getAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetAll(options?: any) {
            return (await ManufacturersApiFp(configuration).manufacturerControllerGetAll(options))(basePath);
        },
        /**
         * 
         * @summary getAllWithImage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetAllWithImage(options?: any) {
            return (await ManufacturersApiFp(configuration).manufacturerControllerGetAllWithImage(options))(basePath);
        },
        /**
         * 
         * @summary getManufacturerById
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetManufacturerById(id: string, options?: any) {
            return (await ManufacturersApiFp(configuration).manufacturerControllerGetManufacturerById(id, options))(basePath);
        },
        /**
         * 
         * @summary getManufacturerCategories
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetManufacturerCategories(id: string, options?: any) {
            return (await ManufacturersApiFp(configuration).manufacturerControllerGetManufacturerCategories(id, options))(basePath);
        },
        /**
         * 
         * @summary getManufacturerGroupedByName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetManufacturerGroupedByName(options?: any) {
            return (await ManufacturersApiFp(configuration).manufacturerControllerGetManufacturerGroupedByName(options))(basePath);
        },
        /**
         * 
         * @summary getManufacturerProducts
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetManufacturerProducts(id: string, options?: any) {
            return (await ManufacturersApiFp(configuration).manufacturerControllerGetManufacturerProducts(id, options))(basePath);
        },
        /**
         * 
         * @summary getManufacturers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerGetManufacturers(options?: any) {
            return (await ManufacturersApiFp(configuration).manufacturerControllerGetManufacturers(options))(basePath);
        },
        /**
         * 
         * @summary removeImageFromManufacturer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerRemoveImageFromManufacturer(id: string, options?: any) {
            return (await ManufacturersApiFp(configuration).manufacturerControllerRemoveImageFromManufacturer(id, options))(basePath);
        },
        /**
         * 
         * @summary updateManufacturerStatus
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerControllerUpdateManufacturerStatus(id: string, options?: any) {
            return (await ManufacturersApiFp(configuration).manufacturerControllerUpdateManufacturerStatus(id, options))(basePath);
        },
    };
};

/**
 * ManufacturersApi - object-oriented interface
 * @export
 * @class ManufacturersApi
 * @extends {BaseAPI}
 */
export class ManufacturersApi extends BaseAPI {
    /**
     * 
     * @summary addImageToManufacturer
     * @param {UploadImageDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public async addImageToManufacturer(body: UploadImageDto, id: string, options?: any) {
        return (await ManufacturersApiFp(this.configuration).manufacturerControllerAddImageToManufacturer(body, id, options))(this.basePath);
    }

    /**
     * 
     * @summary createOrUpdateManufacturer
     * @param {CreateManufacturerDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public async createOrUpdateManufacturer(body: CreateManufacturerDto, options?: any) {
        return (await ManufacturersApiFp(this.configuration).manufacturerControllerCreateOrUpdateManufacturer(body, options))(this.basePath);
    }

    /**
     * 
     * @summary createOrUpdateManufacturers
     * @param {Array<CreateManufacturerDto>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public async createOrUpdateManufacturers(body: Array<CreateManufacturerDto>, options?: any) {
        return (await ManufacturersApiFp(this.configuration).manufacturerControllerCreateOrUpdateManufacturers(body, options))(this.basePath);
    }

    /**
     * 
     * @summary getAll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public async getAll(options?: any) {
        return (await ManufacturersApiFp(this.configuration).manufacturerControllerGetAll(options))(this.basePath);
    }

    /**
     * 
     * @summary getAllWithImage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public async getAllWithImage(options?: any) {
        return (await ManufacturersApiFp(this.configuration).manufacturerControllerGetAllWithImage(options))(this.basePath);
    }

    /**
     * 
     * @summary getManufacturerById
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public async getManufacturerById(id: string, options?: any) {
        return (await ManufacturersApiFp(this.configuration).manufacturerControllerGetManufacturerById(id, options))(this.basePath);
    }

    /**
     * 
     * @summary getManufacturerCategories
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public async getManufacturerCategories(id: string, options?: any) {
        return (await ManufacturersApiFp(this.configuration).manufacturerControllerGetManufacturerCategories(id, options))(this.basePath);
    }

    /**
     * 
     * @summary getManufacturerGroupedByName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public async getManufacturerGroupedByName(options?: any) {
        return (await ManufacturersApiFp(this.configuration).manufacturerControllerGetManufacturerGroupedByName(options))(this.basePath);
    }

    /**
     * 
     * @summary getManufacturerProducts
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public async getManufacturerProducts(id: string, options?: any) {
        return (await ManufacturersApiFp(this.configuration).manufacturerControllerGetManufacturerProducts(id, options))(this.basePath);
    }

    /**
     * 
     * @summary getManufacturers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public async getManufacturers(options?: any) {
        return (await ManufacturersApiFp(this.configuration).manufacturerControllerGetManufacturers(options))(this.basePath);
    }

    /**
     * 
     * @summary removeImageFromManufacturer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public async removeImageFromManufacturer(id: string, options?: any) {
        return (await ManufacturersApiFp(this.configuration).manufacturerControllerRemoveImageFromManufacturer(id, options))(this.basePath);
    }

    /**
     * 
     * @summary updateManufacturerStatus
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public async updateManufacturerStatus(id: string, options?: any) {
        return (await ManufacturersApiFp(this.configuration).manufacturerControllerUpdateManufacturerStatus(id, options))(this.basePath);
    }

}
/**
 * ProductsApi - fetch parameter creator
 * @export
 */
export const ProductsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addImageToProduct
         * @param {UploadImageDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerAddImageToProduct(body: UploadImageDto, id: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling productControllerAddImageToProduct.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling productControllerAddImageToProduct.');
            }
            const localVarPath = `/api/products/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UploadImageDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addProductManufacturer
         * @param {AddManufacturerToProductDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerAddProductManufacturer(body: AddManufacturerToProductDto, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling productControllerAddProductManufacturer.');
            }
            const localVarPath = `/api/products/manufacturer/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddManufacturerToProductDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createOrUpdateProduct
         * @param {CreateOrUpdateProductDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerCreateOrUpdateProduct(body: CreateOrUpdateProductDto, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling productControllerCreateOrUpdateProduct.');
            }
            const localVarPath = `/api/products/create-or-update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateOrUpdateProductDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createOrUpdateProducts
         * @param {Array<CreateOrUpdateProductDto>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerCreateOrUpdateProducts(body: Array<CreateOrUpdateProductDto>, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling productControllerCreateOrUpdateProducts.');
            }
            const localVarPath = `/api/products/create-or-update/many`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;CreateOrUpdateProductDto&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createPropertiesForProduct
         * @param {Array<CreateOrUpdatePropertyDto>} body 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerCreatePropertiesForProduct(body: Array<CreateOrUpdatePropertyDto>, productId: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling productControllerCreatePropertiesForProduct.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling productControllerCreatePropertiesForProduct.');
            }
            const localVarPath = `/api/products/{productId}/properties/create-or-update/many`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;CreateOrUpdatePropertyDto&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteProduct
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerDeleteProduct(id: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling productControllerDeleteProduct.');
            }
            const localVarPath = `/api/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteProductProperty
         * @param {string} id 
         * @param {string} propId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerDeleteProductProperty(id: string, propId: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling productControllerDeleteProductProperty.');
            }
            // verify required parameter 'propId' is not null or undefined
            if (propId === null || propId === undefined) {
                throw new RequiredError('propId','Required parameter propId was null or undefined when calling productControllerDeleteProductProperty.');
            }
            const localVarPath = `/api/products/{id}/property/{propId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"propId"}}`, encodeURIComponent(String(propId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary filterProductsByProperties
         * @param {string} [filter] property ids for filtering (\&quot;/\&quot; - separator)
         * @param {number} [take] default value: 10
         * @param {number} [skip] default value: 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerFilterProductsByProperties(filter?: string, take?: number, skip?: number, options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/products/filter`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetAllProducts(options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/products`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCategoryPaginatedProducts
         * @param {string} categoryId 
         * @param {string} [manufacturerId] 
         * @param {string} [order] 
         * @param {string} [filter] property ids for filtering (\&quot;/\&quot; - separator)
         * @param {string} [q] 
         * @param {number} [take] default value: 10
         * @param {number} [skip] default value: 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetCategoryPaginatedProducts(categoryId: string, manufacturerId?: string, order?: string, filter?: string, q?: string, take?: number, skip?: number, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling productControllerGetCategoryPaginatedProducts.');
            }
            const localVarPath = `/api/products/category/{categoryId}/paginated`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturerId'] = manufacturerId;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCategoryPropertiesGroupedByName
         * @param {string} categoryId 
         * @param {string} [manufacturerId] 
         * @param {string} [filter] property ids for filtering (\&quot;/\&quot; - separator)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetCategoryPropertiesGroupedByName(categoryId: string, manufacturerId?: string, filter?: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling productControllerGetCategoryPropertiesGroupedByName.');
            }
            const localVarPath = `/api/products/category/{categoryId}/properties/grouped-by-name`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturerId'] = manufacturerId;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPaginatedProducts
         * @param {string} [order] 
         * @param {string} [q] 
         * @param {number} [take] default value: 10
         * @param {number} [skip] default value: 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetPaginatedProducts(order?: string, q?: string, take?: number, skip?: number, options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/products/paginated`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProductWithData
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetProductWithData(id: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling productControllerGetProductWithData.');
            }
            const localVarPath = `/api/products/id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProductWithDataBySlug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetProductWithDataBySlug(slug: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling productControllerGetProductWithDataBySlug.');
            }
            const localVarPath = `/api/products/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchProductsByName
         * @param {string} [c] CategoryId
         * @param {string} [q] Search string
         * @param {number} [take] default value: 10
         * @param {number} [skip] default value: 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerSearchProductsByName(c?: string, q?: string, take?: number, skip?: number, options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/products/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (c !== undefined) {
                localVarQueryParameter['c'] = c;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addImageToProduct
         * @param {UploadImageDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerAddImageToProduct(body: UploadImageDto, id: string, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await ProductsApiFetchParamCreator(configuration).productControllerAddImageToProduct(body, id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary addProductManufacturer
         * @param {AddManufacturerToProductDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerAddProductManufacturer(body: AddManufacturerToProductDto, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await ProductsApiFetchParamCreator(configuration).productControllerAddProductManufacturer(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createOrUpdateProduct
         * @param {CreateOrUpdateProductDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerCreateOrUpdateProduct(body: CreateOrUpdateProductDto, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await ProductsApiFetchParamCreator(configuration).productControllerCreateOrUpdateProduct(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createOrUpdateProducts
         * @param {Array<CreateOrUpdateProductDto>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerCreateOrUpdateProducts(body: Array<CreateOrUpdateProductDto>, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await ProductsApiFetchParamCreator(configuration).productControllerCreateOrUpdateProducts(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createPropertiesForProduct
         * @param {Array<CreateOrUpdatePropertyDto>} body 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerCreatePropertiesForProduct(body: Array<CreateOrUpdatePropertyDto>, productId: string, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await ProductsApiFetchParamCreator(configuration).productControllerCreatePropertiesForProduct(body, productId, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteProduct
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerDeleteProduct(id: string, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await ProductsApiFetchParamCreator(configuration).productControllerDeleteProduct(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteProductProperty
         * @param {string} id 
         * @param {string} propId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerDeleteProductProperty(id: string, propId: string, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await ProductsApiFetchParamCreator(configuration).productControllerDeleteProductProperty(id, propId, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary filterProductsByProperties
         * @param {string} [filter] property ids for filtering (\&quot;/\&quot; - separator)
         * @param {number} [take] default value: 10
         * @param {number} [skip] default value: 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerFilterProductsByProperties(filter?: string, take?: number, skip?: number, options?: any): Promise<(basePath?: string) => Promise<PaginatedProductDto>> {
            const localVarFetchArgs = await ProductsApiFetchParamCreator(configuration).productControllerFilterProductsByProperties(filter, take, skip, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetAllProducts(options?: any): Promise<(basePath?: string) => Promise<Array<ProductDto>>> {
            const localVarFetchArgs = await ProductsApiFetchParamCreator(configuration).productControllerGetAllProducts(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCategoryPaginatedProducts
         * @param {string} categoryId 
         * @param {string} [manufacturerId] 
         * @param {string} [order] 
         * @param {string} [filter] property ids for filtering (\&quot;/\&quot; - separator)
         * @param {string} [q] 
         * @param {number} [take] default value: 10
         * @param {number} [skip] default value: 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetCategoryPaginatedProducts(categoryId: string, manufacturerId?: string, order?: string, filter?: string, q?: string, take?: number, skip?: number, options?: any): Promise<(basePath?: string) => Promise<PaginatedProductDto>> {
            const localVarFetchArgs = await ProductsApiFetchParamCreator(configuration).productControllerGetCategoryPaginatedProducts(categoryId, manufacturerId, order, filter, q, take, skip, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCategoryPropertiesGroupedByName
         * @param {string} categoryId 
         * @param {string} [manufacturerId] 
         * @param {string} [filter] property ids for filtering (\&quot;/\&quot; - separator)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetCategoryPropertiesGroupedByName(categoryId: string, manufacturerId?: string, filter?: string, options?: any): Promise<(basePath?: string) => Promise<ProductCategoryDataDto>> {
            const localVarFetchArgs = await ProductsApiFetchParamCreator(configuration).productControllerGetCategoryPropertiesGroupedByName(categoryId, manufacturerId, filter, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getPaginatedProducts
         * @param {string} [order] 
         * @param {string} [q] 
         * @param {number} [take] default value: 10
         * @param {number} [skip] default value: 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetPaginatedProducts(order?: string, q?: string, take?: number, skip?: number, options?: any): Promise<(basePath?: string) => Promise<PaginatedProductDto>> {
            const localVarFetchArgs = await ProductsApiFetchParamCreator(configuration).productControllerGetPaginatedProducts(order, q, take, skip, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getProductWithData
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetProductWithData(id: string, options?: any): Promise<(basePath?: string) => Promise<ProductWithDataDto>> {
            const localVarFetchArgs = await ProductsApiFetchParamCreator(configuration).productControllerGetProductWithData(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getProductWithDataBySlug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetProductWithDataBySlug(slug: string, options?: any): Promise<(basePath?: string) => Promise<ProductWithDataDto>> {
            const localVarFetchArgs = await ProductsApiFetchParamCreator(configuration).productControllerGetProductWithDataBySlug(slug, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary searchProductsByName
         * @param {string} [c] CategoryId
         * @param {string} [q] Search string
         * @param {number} [take] default value: 10
         * @param {number} [skip] default value: 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerSearchProductsByName(c?: string, q?: string, take?: number, skip?: number, options?: any): Promise<(basePath?: string) => Promise<PaginatedProductDto>> {
            const localVarFetchArgs = await ProductsApiFetchParamCreator(configuration).productControllerSearchProductsByName(c, q, take, skip, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary addImageToProduct
         * @param {UploadImageDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerAddImageToProduct(body: UploadImageDto, id: string, options?: any) {
            return (await ProductsApiFp(configuration).productControllerAddImageToProduct(body, id, options))(basePath);
        },
        /**
         * 
         * @summary addProductManufacturer
         * @param {AddManufacturerToProductDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerAddProductManufacturer(body: AddManufacturerToProductDto, options?: any) {
            return (await ProductsApiFp(configuration).productControllerAddProductManufacturer(body, options))(basePath);
        },
        /**
         * 
         * @summary createOrUpdateProduct
         * @param {CreateOrUpdateProductDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerCreateOrUpdateProduct(body: CreateOrUpdateProductDto, options?: any) {
            return (await ProductsApiFp(configuration).productControllerCreateOrUpdateProduct(body, options))(basePath);
        },
        /**
         * 
         * @summary createOrUpdateProducts
         * @param {Array<CreateOrUpdateProductDto>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerCreateOrUpdateProducts(body: Array<CreateOrUpdateProductDto>, options?: any) {
            return (await ProductsApiFp(configuration).productControllerCreateOrUpdateProducts(body, options))(basePath);
        },
        /**
         * 
         * @summary createPropertiesForProduct
         * @param {Array<CreateOrUpdatePropertyDto>} body 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerCreatePropertiesForProduct(body: Array<CreateOrUpdatePropertyDto>, productId: string, options?: any) {
            return (await ProductsApiFp(configuration).productControllerCreatePropertiesForProduct(body, productId, options))(basePath);
        },
        /**
         * 
         * @summary deleteProduct
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerDeleteProduct(id: string, options?: any) {
            return (await ProductsApiFp(configuration).productControllerDeleteProduct(id, options))(basePath);
        },
        /**
         * 
         * @summary deleteProductProperty
         * @param {string} id 
         * @param {string} propId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerDeleteProductProperty(id: string, propId: string, options?: any) {
            return (await ProductsApiFp(configuration).productControllerDeleteProductProperty(id, propId, options))(basePath);
        },
        /**
         * 
         * @summary filterProductsByProperties
         * @param {string} [filter] property ids for filtering (\&quot;/\&quot; - separator)
         * @param {number} [take] default value: 10
         * @param {number} [skip] default value: 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerFilterProductsByProperties(filter?: string, take?: number, skip?: number, options?: any) {
            return (await ProductsApiFp(configuration).productControllerFilterProductsByProperties(filter, take, skip, options))(basePath);
        },
        /**
         * 
         * @summary getAllProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetAllProducts(options?: any) {
            return (await ProductsApiFp(configuration).productControllerGetAllProducts(options))(basePath);
        },
        /**
         * 
         * @summary getCategoryPaginatedProducts
         * @param {string} categoryId 
         * @param {string} [manufacturerId] 
         * @param {string} [order] 
         * @param {string} [filter] property ids for filtering (\&quot;/\&quot; - separator)
         * @param {string} [q] 
         * @param {number} [take] default value: 10
         * @param {number} [skip] default value: 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetCategoryPaginatedProducts(categoryId: string, manufacturerId?: string, order?: string, filter?: string, q?: string, take?: number, skip?: number, options?: any) {
            return (await ProductsApiFp(configuration).productControllerGetCategoryPaginatedProducts(categoryId, manufacturerId, order, filter, q, take, skip, options))(basePath);
        },
        /**
         * 
         * @summary getCategoryPropertiesGroupedByName
         * @param {string} categoryId 
         * @param {string} [manufacturerId] 
         * @param {string} [filter] property ids for filtering (\&quot;/\&quot; - separator)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetCategoryPropertiesGroupedByName(categoryId: string, manufacturerId?: string, filter?: string, options?: any) {
            return (await ProductsApiFp(configuration).productControllerGetCategoryPropertiesGroupedByName(categoryId, manufacturerId, filter, options))(basePath);
        },
        /**
         * 
         * @summary getPaginatedProducts
         * @param {string} [order] 
         * @param {string} [q] 
         * @param {number} [take] default value: 10
         * @param {number} [skip] default value: 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetPaginatedProducts(order?: string, q?: string, take?: number, skip?: number, options?: any) {
            return (await ProductsApiFp(configuration).productControllerGetPaginatedProducts(order, q, take, skip, options))(basePath);
        },
        /**
         * 
         * @summary getProductWithData
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetProductWithData(id: string, options?: any) {
            return (await ProductsApiFp(configuration).productControllerGetProductWithData(id, options))(basePath);
        },
        /**
         * 
         * @summary getProductWithDataBySlug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetProductWithDataBySlug(slug: string, options?: any) {
            return (await ProductsApiFp(configuration).productControllerGetProductWithDataBySlug(slug, options))(basePath);
        },
        /**
         * 
         * @summary searchProductsByName
         * @param {string} [c] CategoryId
         * @param {string} [q] Search string
         * @param {number} [take] default value: 10
         * @param {number} [skip] default value: 0
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerSearchProductsByName(c?: string, q?: string, take?: number, skip?: number, options?: any) {
            return (await ProductsApiFp(configuration).productControllerSearchProductsByName(c, q, take, skip, options))(basePath);
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @summary addImageToProduct
     * @param {UploadImageDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public async addImageToProduct(body: UploadImageDto, id: string, options?: any) {
        return (await ProductsApiFp(this.configuration).productControllerAddImageToProduct(body, id, options))(this.basePath);
    }

    /**
     * 
     * @summary addProductManufacturer
     * @param {AddManufacturerToProductDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public async addProductManufacturer(body: AddManufacturerToProductDto, options?: any) {
        return (await ProductsApiFp(this.configuration).productControllerAddProductManufacturer(body, options))(this.basePath);
    }

    /**
     * 
     * @summary createOrUpdateProduct
     * @param {CreateOrUpdateProductDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public async createOrUpdateProduct(body: CreateOrUpdateProductDto, options?: any) {
        return (await ProductsApiFp(this.configuration).productControllerCreateOrUpdateProduct(body, options))(this.basePath);
    }

    /**
     * 
     * @summary createOrUpdateProducts
     * @param {Array<CreateOrUpdateProductDto>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public async createOrUpdateProducts(body: Array<CreateOrUpdateProductDto>, options?: any) {
        return (await ProductsApiFp(this.configuration).productControllerCreateOrUpdateProducts(body, options))(this.basePath);
    }

    /**
     * 
     * @summary createPropertiesForProduct
     * @param {Array<CreateOrUpdatePropertyDto>} body 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public async createPropertiesForProduct(body: Array<CreateOrUpdatePropertyDto>, productId: string, options?: any) {
        return (await ProductsApiFp(this.configuration).productControllerCreatePropertiesForProduct(body, productId, options))(this.basePath);
    }

    /**
     * 
     * @summary deleteProduct
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public async deleteProduct(id: string, options?: any) {
        return (await ProductsApiFp(this.configuration).productControllerDeleteProduct(id, options))(this.basePath);
    }

    /**
     * 
     * @summary deleteProductProperty
     * @param {string} id 
     * @param {string} propId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public async deleteProductProperty(id: string, propId: string, options?: any) {
        return (await ProductsApiFp(this.configuration).productControllerDeleteProductProperty(id, propId, options))(this.basePath);
    }

    /**
     * 
     * @summary filterProductsByProperties
     * @param {string} [filter] property ids for filtering (\&quot;/\&quot; - separator)
     * @param {number} [take] default value: 10
     * @param {number} [skip] default value: 0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public async filterProductsByProperties(filter?: string, take?: number, skip?: number, options?: any) {
        return (await ProductsApiFp(this.configuration).productControllerFilterProductsByProperties(filter, take, skip, options))(this.basePath);
    }

    /**
     * 
     * @summary getAllProducts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public async getAllProducts(options?: any) {
        return (await ProductsApiFp(this.configuration).productControllerGetAllProducts(options))(this.basePath);
    }

    /**
     * 
     * @summary getCategoryPaginatedProducts
     * @param {string} categoryId 
     * @param {string} [manufacturerId] 
     * @param {string} [order] 
     * @param {string} [filter] property ids for filtering (\&quot;/\&quot; - separator)
     * @param {string} [q] 
     * @param {number} [take] default value: 10
     * @param {number} [skip] default value: 0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public async getCategoryPaginatedProducts(categoryId: string, manufacturerId?: string, order?: string, filter?: string, q?: string, take?: number, skip?: number, options?: any) {
        return (await ProductsApiFp(this.configuration).productControllerGetCategoryPaginatedProducts(categoryId, manufacturerId, order, filter, q, take, skip, options))(this.basePath);
    }

    /**
     * 
     * @summary getCategoryPropertiesGroupedByName
     * @param {string} categoryId 
     * @param {string} [manufacturerId] 
     * @param {string} [filter] property ids for filtering (\&quot;/\&quot; - separator)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public async getCategoryPropertiesGroupedByName(categoryId: string, manufacturerId?: string, filter?: string, options?: any) {
        return (await ProductsApiFp(this.configuration).productControllerGetCategoryPropertiesGroupedByName(categoryId, manufacturerId, filter, options))(this.basePath);
    }

    /**
     * 
     * @summary getPaginatedProducts
     * @param {string} [order] 
     * @param {string} [q] 
     * @param {number} [take] default value: 10
     * @param {number} [skip] default value: 0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public async getPaginatedProducts(order?: string, q?: string, take?: number, skip?: number, options?: any) {
        return (await ProductsApiFp(this.configuration).productControllerGetPaginatedProducts(order, q, take, skip, options))(this.basePath);
    }

    /**
     * 
     * @summary getProductWithData
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public async getProductWithData(id: string, options?: any) {
        return (await ProductsApiFp(this.configuration).productControllerGetProductWithData(id, options))(this.basePath);
    }

    /**
     * 
     * @summary getProductWithDataBySlug
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public async getProductWithDataBySlug(slug: string, options?: any) {
        return (await ProductsApiFp(this.configuration).productControllerGetProductWithDataBySlug(slug, options))(this.basePath);
    }

    /**
     * 
     * @summary searchProductsByName
     * @param {string} [c] CategoryId
     * @param {string} [q] Search string
     * @param {number} [take] default value: 10
     * @param {number} [skip] default value: 0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public async searchProductsByName(c?: string, q?: string, take?: number, skip?: number, options?: any) {
        return (await ProductsApiFp(this.configuration).productControllerSearchProductsByName(c, q, take, skip, options))(this.basePath);
    }

}
/**
 * PropertiesApi - fetch parameter creator
 * @export
 */
export const PropertiesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createOrUpdateProperties
         * @param {Array<CreateOrUpdatePropertyDto>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyControllerCreateOrUpdateProperties(body: Array<CreateOrUpdatePropertyDto>, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling propertyControllerCreateOrUpdateProperties.');
            }
            const localVarPath = `/api/properties/create-or-update/many`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;CreateOrUpdatePropertyDto&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createOrUpdateProperty
         * @param {CreateOrUpdatePropertyDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyControllerCreateOrUpdateProperty(body: CreateOrUpdatePropertyDto, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling propertyControllerCreateOrUpdateProperty.');
            }
            const localVarPath = `/api/properties/create-or-update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateOrUpdatePropertyDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyControllerGetAll(options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/properties`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPropertyById
         * @param {string} groupId 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyControllerGetPropertyById(groupId: string, value: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling propertyControllerGetPropertyById.');
            }
            // verify required parameter 'value' is not null or undefined
            if (value === null || value === undefined) {
                throw new RequiredError('value','Required parameter value was null or undefined when calling propertyControllerGetPropertyById.');
            }
            const localVarPath = `/api/properties/group/{groupId}/value/{value}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"value"}}`, encodeURIComponent(String(value)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPropertyProducts
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyControllerGetPropertyProducts(id: string, options: any = {}): Promise<FetchArgs> {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling propertyControllerGetPropertyProducts.');
            }
            const localVarPath = `/api/properties/{id}/products`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertiesApi - functional programming interface
 * @export
 */
export const PropertiesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createOrUpdateProperties
         * @param {Array<CreateOrUpdatePropertyDto>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyControllerCreateOrUpdateProperties(body: Array<CreateOrUpdatePropertyDto>, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await PropertiesApiFetchParamCreator(configuration).propertyControllerCreateOrUpdateProperties(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createOrUpdateProperty
         * @param {CreateOrUpdatePropertyDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyControllerCreateOrUpdateProperty(body: CreateOrUpdatePropertyDto, options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await PropertiesApiFetchParamCreator(configuration).propertyControllerCreateOrUpdateProperty(body, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyControllerGetAll(options?: any): Promise<(basePath?: string) => Promise<Array<PropertyDto>>> {
            const localVarFetchArgs = await PropertiesApiFetchParamCreator(configuration).propertyControllerGetAll(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getPropertyById
         * @param {string} groupId 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyControllerGetPropertyById(groupId: string, value: string, options?: any): Promise<(basePath?: string) => Promise<PropertyDto>> {
            const localVarFetchArgs = await PropertiesApiFetchParamCreator(configuration).propertyControllerGetPropertyById(groupId, value, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getPropertyProducts
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyControllerGetPropertyProducts(id: string, options?: any): Promise<(basePath?: string) => Promise<Array<ProductDto>>> {
            const localVarFetchArgs = await PropertiesApiFetchParamCreator(configuration).propertyControllerGetPropertyProducts(id, options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PropertiesApi - factory interface
 * @export
 */
export const PropertiesApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary createOrUpdateProperties
         * @param {Array<CreateOrUpdatePropertyDto>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyControllerCreateOrUpdateProperties(body: Array<CreateOrUpdatePropertyDto>, options?: any) {
            return (await PropertiesApiFp(configuration).propertyControllerCreateOrUpdateProperties(body, options))(basePath);
        },
        /**
         * 
         * @summary createOrUpdateProperty
         * @param {CreateOrUpdatePropertyDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyControllerCreateOrUpdateProperty(body: CreateOrUpdatePropertyDto, options?: any) {
            return (await PropertiesApiFp(configuration).propertyControllerCreateOrUpdateProperty(body, options))(basePath);
        },
        /**
         * 
         * @summary getAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyControllerGetAll(options?: any) {
            return (await PropertiesApiFp(configuration).propertyControllerGetAll(options))(basePath);
        },
        /**
         * 
         * @summary getPropertyById
         * @param {string} groupId 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyControllerGetPropertyById(groupId: string, value: string, options?: any) {
            return (await PropertiesApiFp(configuration).propertyControllerGetPropertyById(groupId, value, options))(basePath);
        },
        /**
         * 
         * @summary getPropertyProducts
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyControllerGetPropertyProducts(id: string, options?: any) {
            return (await PropertiesApiFp(configuration).propertyControllerGetPropertyProducts(id, options))(basePath);
        },
    };
};

/**
 * PropertiesApi - object-oriented interface
 * @export
 * @class PropertiesApi
 * @extends {BaseAPI}
 */
export class PropertiesApi extends BaseAPI {
    /**
     * 
     * @summary createOrUpdateProperties
     * @param {Array<CreateOrUpdatePropertyDto>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    public async createOrUpdateProperties(body: Array<CreateOrUpdatePropertyDto>, options?: any) {
        return (await PropertiesApiFp(this.configuration).propertyControllerCreateOrUpdateProperties(body, options))(this.basePath);
    }

    /**
     * 
     * @summary createOrUpdateProperty
     * @param {CreateOrUpdatePropertyDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    public async createOrUpdateProperty(body: CreateOrUpdatePropertyDto, options?: any) {
        return (await PropertiesApiFp(this.configuration).propertyControllerCreateOrUpdateProperty(body, options))(this.basePath);
    }

    /**
     * 
     * @summary getAll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    public async getAll(options?: any) {
        return (await PropertiesApiFp(this.configuration).propertyControllerGetAll(options))(this.basePath);
    }

    /**
     * 
     * @summary getPropertyById
     * @param {string} groupId 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    public async getPropertyById(groupId: string, value: string, options?: any) {
        return (await PropertiesApiFp(this.configuration).propertyControllerGetPropertyById(groupId, value, options))(this.basePath);
    }

    /**
     * 
     * @summary getPropertyProducts
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    public async getPropertyProducts(id: string, options?: any) {
        return (await PropertiesApiFp(this.configuration).propertyControllerGetPropertyProducts(id, options))(this.basePath);
    }

}
/**
 * SeederApi - fetch parameter creator
 * @export
 */
export const SeederApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary seedData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async seederControllerSeedData(options: any = {}): Promise<FetchArgs> {
            const localVarPath = `/api/seed`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // bearerAuth required
            if (configuration && configuration.accessToken) {
			    const localVarAccessTokenValue = await configuration.accessToken("bearer", []);
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SeederApi - functional programming interface
 * @export
 */
export const SeederApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary seedData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async seederControllerSeedData(options?: any): Promise<(basePath?: string) => Promise<Response>> {
            const localVarFetchArgs = await SeederApiFetchParamCreator(configuration).seederControllerSeedData(options);
            return (basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SeederApi - factory interface
 * @export
 */
export const SeederApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary seedData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async seederControllerSeedData(options?: any) {
            return (await SeederApiFp(configuration).seederControllerSeedData(options))(basePath);
        },
    };
};

/**
 * SeederApi - object-oriented interface
 * @export
 * @class SeederApi
 * @extends {BaseAPI}
 */
export class SeederApi extends BaseAPI {
    /**
     * 
     * @summary seedData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeederApi
     */
    public async seedData(options?: any) {
        return (await SeederApiFp(this.configuration).seederControllerSeedData(options))(this.basePath);
    }

}
