import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import {
  BinaryFileData,
  BinaryFileLoadType,
  FileLoadType,
  StringFileData,
  TextFileLoadType,
} from '../../../types';
import { getFileName, loadFile } from '../../../utils';
import { showErrorModal } from '../ErrorModal';

interface InputProps<T extends FileLoadType> {
  onLoadFiles: (
    data: (T extends TextFileLoadType ? StringFileData : BinaryFileData)[]
  ) => void;
  accept?: string;
  multiple?: boolean;
  loadType: T extends TextFileLoadType ? TextFileLoadType : BinaryFileLoadType;
  fileValidate?: (file: File) => boolean;
}

type ResultData<T> = T extends TextFileLoadType
  ? StringFileData
  : BinaryFileData;

function FileInput<T extends FileLoadType>(props: InputProps<T>) {
  const fileInput = useRef<HTMLInputElement>(null);
  useEffect(() => {
    fileInput.current?.click();
  }, []);

  const handleLoadImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { files },
    } = event;
    try {
      if (!files?.length) return;
      const resultArr: ResultData<T>[] = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const isValid = props.fileValidate ? props.fileValidate(file) : true;
        if (!isValid) continue;
        const data = await loadFile(file, props.loadType);
        if (data === null) continue;
        resultArr.push({
          data: data,
          fileName: getFileName(file.name) || '',
          fileType: file.type,
        } as ResultData<T>);
      }
      props.onLoadFiles(resultArr);
    } catch (error) {
      props.onLoadFiles([]);
      showErrorModal(error);
    }
  };

  const resetFileInput = (e: any) => {
    e.target.value = null;
  };
  return (
    <input
      ref={fileInput}
      hidden
      type="file"
      accept={props.accept}
      className="visually-hidden position-absolute"
      multiple={props.multiple}
      onChange={handleLoadImage}
      onClick={resetFileInput}
    />
  );
}

export default FileInput;

export function showFileInput<T extends FileLoadType>(props: InputProps<T>) {
  const modal = document.createElement('div');
  document.body.appendChild(modal);
  ReactDOM.render(
    <FileInput
      {...props}
      onLoadFiles={(result) => {
        props.onLoadFiles(result as ResultData<T>[]);
        ReactDOM.unmountComponentAtNode(modal);
        document.body.removeChild(modal);
      }}
    />,
    modal
  );
}
