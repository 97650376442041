import React, {
  FormEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Button, Form } from 'react-bootstrap';
import cn from 'classnames';
import appContext from '../../appContext';
import { showErrorModal } from '../shared/ErrorModal';

interface Props {
  test?: string;
}

const Login: FunctionComponent<Props> = (props) => {
  const { apiClient } = useContext(appContext);
  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');
  const [error, setError] = useState('');

  useEffect(
    function clearError() {
      if (error.length > 0) {
        setError('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [login, pass]
  );

  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    apiClient.authApi
      .login({ username: login, password: pass })
      .then((resp) => {
        localStorage.setItem('token', resp.accessToken);
        window.location.reload();
      })
      .catch((e) => {
        if (e.status === 401) {
          setError('Неверный логин или пароль');
        } else {
          showErrorModal(e);
        }
      });
  };

  return (
    <div>
      <Form
        className={cn('border border-3 rounded-3 bg-light p-3', {
          'border-danger': error.length > 0,
        })}
        onSubmit={handleSubmitForm}
      >
        <Form.Text className="text-danger">{error}</Form.Text>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Login</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter login"
            value={login}
            onChange={(e) => setLogin(e.currentTarget.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={pass}
            onChange={(e) => setPass(e.currentTarget.value)}
          />
        </Form.Group>
        <Button type="submit">Sign in</Button>
      </Form>
    </div>
  );
};

export default Login;
