import React, { FC } from 'react';

const Logo: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 220 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M40.6695 19.3351C41.2977 17.8335 41.8902 16.4085 42.4877 14.9785C43.5041 12.5473 44.5256 10.1162 45.5369 7.67992C45.7003 7.28154 45.8995 6.9853 46.3847 7.08235C46.9057 7.18449 47.5697 6.76569 47.8965 7.55224C49.5054 11.4237 51.1296 15.29 52.7435 19.1615C52.7639 19.2074 52.7384 19.2687 52.7282 19.3862C52.1255 19.3862 51.5177 19.427 50.9201 19.3657C50.7158 19.3453 50.4605 19.1053 50.3685 18.901C50.0519 18.2217 49.7812 17.522 49.5258 16.8171C49.3726 16.3932 49.1428 16.2145 48.6678 16.2247C47.3347 16.2553 45.9966 16.2042 44.6635 16.2604C44.3979 16.2706 44.0813 16.5515 43.9025 16.7967C43.6931 17.0776 43.6063 17.4505 43.4684 17.7825C42.7431 19.4934 42.7431 19.4934 40.6695 19.3351ZM44.9342 14.197C46.1345 14.197 47.2326 14.197 48.4379 14.197C47.8608 12.7823 47.3092 11.4288 46.6861 9.91188C46.0579 11.4492 45.5063 12.7976 44.9342 14.197Z"
      fill="currentColor"
    />
    <path
      d="M73.541 22.8746C72.8055 22.8746 72.2079 22.8746 71.5491 22.8746C71.5491 19.1002 71.5491 15.3564 71.5491 11.4901C72.2488 11.7455 73.0864 11.0611 73.6687 12.0468C74.5369 11.4135 75.584 11.107 76.6719 11.3982C77.3103 11.5718 77.9743 11.9395 78.4391 12.4043C79.6802 13.6505 79.818 16.0817 78.8476 17.7007C77.8721 19.3351 76.0641 19.8969 73.5359 19.3096C73.541 20.4792 73.541 21.6284 73.541 22.8746ZM73.5155 15.5914C73.5155 15.7854 73.5155 15.9795 73.5155 16.1736C73.5155 17.3994 73.8168 17.6905 75.0681 17.6956C75.206 17.6956 75.3491 17.6956 75.4818 17.6803C76.7025 17.5118 77.5146 16.3626 77.3461 15.05C77.1877 13.8446 76.2633 13.0938 75.0886 13.2113C73.8423 13.339 73.5155 13.8344 73.5155 15.5914Z"
      fill="currentColor"
    />
    <path
      d="M102.398 19.3402C103.675 16.3319 104.916 13.3951 106.162 10.4635C106.576 9.49305 106.969 8.51243 107.439 7.56755C107.562 7.3275 107.929 7.14873 108.215 7.06701C108.609 6.95976 108.992 7.04148 109.114 7.54712C109.145 7.67991 109.232 7.79738 109.288 7.92506C110.892 11.7046 112.49 15.4892 114.094 19.2738C112.628 19.5649 112.618 19.5547 112.061 18.2421C111.801 17.6292 111.489 17.0316 111.29 16.3983C111.117 15.8569 110.846 15.668 110.274 15.6884C108.885 15.7344 107.49 15.719 106.096 15.6935C105.621 15.6833 105.33 15.7854 105.166 16.286C104.942 16.9703 104.625 17.6241 104.344 18.2881C103.854 19.4475 103.854 19.4424 102.398 19.3402ZM108.221 9.0538C107.449 10.9078 106.775 12.532 106.07 14.2174C107.541 14.2174 108.895 14.2174 110.376 14.2174C109.661 12.5116 108.982 10.8823 108.221 9.0538Z"
      fill="currentColor"
    />
    <path
      d="M88.419 16.0357C86.611 16.0357 84.7826 16.0357 82.9592 16.0357C82.9847 17.8591 85.0022 18.3903 87.3772 17.2309C87.5661 17.6854 87.75 18.1349 87.9287 18.569C86.2892 19.8765 83.7611 19.9276 82.2084 18.7273C80.7477 17.6037 80.2982 15.3615 81.1614 13.4922C81.9683 11.7404 84.2258 10.8414 86.1258 11.5156C88.0462 12.2 88.9502 13.9417 88.419 16.0357ZM82.8008 14.672C84.0675 14.672 85.2831 14.672 86.4986 14.672C86.4731 13.6863 85.993 13.1449 85.0839 13.0377C83.8734 12.8946 83.1481 13.3901 82.8008 14.672Z"
      fill="currentColor"
    />
    <path
      d="M61.4466 19.3658C60.803 19.3658 60.2208 19.3658 59.5415 19.3658C59.5415 18.3903 59.5415 17.4352 59.5415 16.429C58.3719 16.429 57.2993 16.429 56.1297 16.429C56.1297 17.3943 56.1297 18.3545 56.1297 19.3454C55.43 19.3454 54.8273 19.3454 54.1531 19.3454C54.1531 16.7763 54.1531 14.2124 54.1531 11.5769C54.7609 11.5769 55.384 11.5769 56.094 11.5769C56.094 12.4963 56.094 13.4309 56.094 14.4218C57.2482 14.4218 58.3208 14.4218 59.4904 14.4218C59.4904 13.4718 59.4904 12.5422 59.4904 11.5667C60.185 11.5667 60.7877 11.5667 61.4415 11.5667C61.4466 14.146 61.4466 16.715 61.4466 19.3658Z"
      fill="currentColor"
    />
    <path
      d="M91.2383 11.5565C93.1791 11.5565 95.0893 11.5565 97.0506 11.5565C97.0506 14.1613 97.0506 16.7303 97.0506 19.3453C96.4224 19.3453 95.8248 19.3453 95.1302 19.3453C95.1302 17.4556 95.1302 15.5556 95.1302 13.5943C94.4151 13.5943 93.792 13.5943 93.0974 13.5943C92.9748 14.4422 92.8829 15.3155 92.7195 16.1736C92.6122 16.7405 92.459 17.3125 92.2496 17.8488C91.7695 19.0644 90.8348 19.5956 89.4354 19.4934C89.3792 18.9061 89.323 18.3034 89.2617 17.6496C90.2985 17.4198 90.6407 16.623 90.7684 15.7497C90.9625 14.386 91.0749 13.007 91.2383 11.5565Z"
      fill="currentColor"
    />
    <path
      d="M142.89 13.6863C142.89 15.6986 142.89 17.476 142.89 19.3198C142.456 19.3198 142.052 19.3198 141.577 19.3198C141.577 16.7916 141.577 14.2226 141.577 11.5974C142.262 11.4135 142.823 11.4544 143.314 12.1694C144.06 13.2624 144.923 14.2839 145.771 15.382C146.562 14.3043 147.354 13.3288 148.018 12.2818C148.467 11.5769 148.998 11.3777 149.811 11.5923C149.811 14.146 149.811 16.7201 149.811 19.3403C149.382 19.3403 148.973 19.3403 148.447 19.3403C148.447 17.5731 148.447 15.7906 148.447 14.0132C148.38 13.9928 148.319 13.9774 148.253 13.957C147.436 15.0551 146.613 16.1481 145.73 17.333C144.795 16.1277 143.917 15.004 142.89 13.6863Z"
      fill="currentColor"
    />
    <path
      d="M11.6898 22.8848C11.6898 23.61 11.6847 24.3302 11.6898 25.0555C11.6949 25.6735 11.3629 25.8829 10.8266 25.6683C8.81938 24.8614 6.81726 24.034 4.82536 23.1861C4.29929 22.9614 4.23288 22.5528 4.6568 22.1289C5.67829 21.1125 6.68957 20.0757 7.75703 19.1053C7.96132 18.9163 8.44143 18.8601 8.72234 18.9572C9.55996 19.2381 10.3823 19.5905 11.1688 19.9889C11.4037 20.1063 11.6336 20.4587 11.6591 20.7243C11.7357 21.4343 11.6898 22.1595 11.6898 22.8848Z"
      fill="currentColor"
    />
    <path
      d="M2.76202 12.4299C2.09295 12.4299 1.42899 12.4452 0.759912 12.4247C0.019332 12.3992 -0.149214 12.1643 0.121481 11.4901C0.913135 9.5084 1.715 7.53181 2.54241 5.56544C2.82332 4.89637 3.15017 4.84018 3.67113 5.35604C4.6773 6.35199 5.67326 7.36326 6.61814 8.41539C6.80711 8.6248 6.87863 9.08958 6.78669 9.36538C6.51089 10.2081 6.17891 11.0355 5.77032 11.817C5.62731 12.0928 5.22891 12.3635 4.92246 12.4043C4.21253 12.4911 3.48728 12.4299 2.76202 12.4299Z"
      fill="currentColor"
    />
    <path
      d="M200.87 18.0071C201.37 18.0429 201.723 18.0685 202.147 18.0991C202.147 19.141 202.147 20.1778 202.147 21.281C201.769 21.281 201.396 21.281 200.926 21.281C200.926 20.6681 200.926 20.0706 200.926 19.3964C198.566 19.3964 196.299 19.3964 193.98 19.3964C193.98 16.7405 193.98 14.1715 193.98 11.5565C194.429 11.5565 194.838 11.5565 195.333 11.5565C195.333 13.676 195.333 15.8058 195.333 17.9969C196.733 17.9969 198.05 17.9969 199.47 17.9969C199.47 15.8927 199.47 13.7731 199.47 11.582C199.966 11.582 200.369 11.582 200.87 11.582C200.87 13.6709 200.87 15.7956 200.87 18.0071Z"
      fill="currentColor"
    />
    <path
      d="M21.8639 14.0387C22.5585 14.0387 23.2582 14.0234 23.9528 14.0438C24.6372 14.0642 24.8875 14.3911 24.6372 15.0091C23.866 16.9142 23.0795 18.8142 22.2725 20.7039C21.9916 21.3628 21.6392 21.4343 21.1386 20.9337C20.1529 19.9531 19.1672 18.9674 18.2376 17.9306C18.0333 17.7058 17.9516 17.2206 18.0384 16.9193C18.2682 16.1481 18.6309 15.4228 18.922 14.672C19.1059 14.197 19.4072 13.9825 19.9384 14.0183C20.5819 14.0744 21.2254 14.0387 21.8639 14.0387Z"
      fill="currentColor"
    />
    <path
      d="M2.88977 14.0387C5.54564 14.0387 5.50988 14.054 6.58244 16.5311C6.91443 17.2972 6.84803 17.7927 6.23003 18.3392C5.33622 19.1308 4.52416 20.0093 3.66611 20.8367C3.043 21.4394 2.73654 21.373 2.40455 20.5609C1.64354 18.7171 0.882533 16.8733 0.136845 15.0244C-0.138957 14.3349 0.0602325 14.054 0.80592 14.0387C1.50053 14.0285 2.19515 14.0387 2.88977 14.0387Z"
      fill="currentColor"
    />
    <path
      d="M13.314 3.68591C13.314 2.96065 13.2884 2.2405 13.3191 1.51524C13.3446 0.948315 13.6102 0.713363 14.2027 0.963628C16.1231 1.7706 18.0486 2.55205 19.969 3.35902C20.6432 3.64504 20.7249 3.97192 20.2295 4.47755C19.2335 5.48883 18.2376 6.50011 17.1854 7.45009C16.9658 7.64418 16.4908 7.67993 16.1691 7.60842C15.689 7.50627 15.2344 7.26623 14.7798 7.05682C13.1812 6.32646 13.3089 6.83209 13.3038 4.8504C13.3089 4.46734 13.3089 4.07918 13.314 3.68591Z"
      fill="currentColor"
    />
    <path
      d="M168.08 19.3096C168.08 16.7456 168.08 14.1715 168.08 11.5565C168.534 11.5565 168.943 11.5565 169.433 11.5565C169.433 13.3952 169.433 15.2134 169.433 17.0368C169.495 17.0674 169.551 17.093 169.612 17.1236C169.939 16.7048 170.271 16.286 170.598 15.8621C171.497 14.6976 172.447 13.5637 173.274 12.3481C173.795 11.5871 174.403 11.3624 175.307 11.5922C175.307 14.1459 175.307 16.715 175.307 19.3402C174.893 19.3402 174.49 19.3402 174.01 19.3402C174.01 17.5271 174.01 15.7037 174.01 13.8855C173.953 13.8548 173.897 13.8242 173.836 13.7935C173.652 14.0029 173.458 14.2021 173.284 14.4269C172.212 15.811 171.088 17.1593 170.092 18.5996C169.561 19.3709 168.948 19.5292 168.08 19.3096Z"
      fill="currentColor"
    />
    <path
      d="M205.298 17.1185C206.519 15.5403 207.785 13.9927 208.939 12.3686C209.491 11.5973 210.094 11.3318 211.013 11.6127C211.013 14.1511 211.013 16.6997 211.013 19.3198C210.584 19.3198 210.16 19.3198 209.644 19.3198C209.644 17.5169 209.644 15.6935 209.644 13.865C209.583 13.8395 209.517 13.8089 209.455 13.7833C208.725 14.7333 207.995 15.6833 207.259 16.6333C206.784 17.2513 206.253 17.8335 205.844 18.4924C205.354 19.284 204.721 19.5752 203.776 19.3147C203.776 16.7712 203.776 14.197 203.776 11.5718C204.184 11.5718 204.588 11.5718 205.078 11.5718C205.078 13.3901 205.078 15.2083 205.078 17.0317C205.15 17.0623 205.226 17.0878 205.298 17.1185Z"
      fill="currentColor"
    />
    <path
      d="M11.6899 3.61951C11.6899 4.28348 11.6541 4.95256 11.7001 5.61653C11.7359 6.15792 11.5111 6.44903 11.0361 6.61247C11.0106 6.62268 10.9851 6.63801 10.9595 6.64822C8.31896 7.81783 8.31897 7.81783 6.27599 5.71867C5.81122 5.24367 5.3362 4.77379 4.88164 4.28859C4.48837 3.86978 4.51901 3.4765 5.07572 3.23645C6.98591 2.42437 8.90121 1.6174 10.8267 0.830851C11.363 0.61123 11.6848 0.846169 11.6899 1.44885C11.695 2.1741 11.6899 2.89936 11.6899 3.61951Z"
      fill="currentColor"
    />
    <path
      d="M115.672 19.5139C115.672 16.7303 115.672 14.1868 115.672 11.5769C117.159 11.5769 118.624 11.4901 120.075 11.6024C121.158 11.6842 121.75 12.3481 121.852 13.1908C121.97 14.151 121.587 14.7486 120.606 15.2287C121.02 15.5454 121.602 15.7905 121.847 16.2298C122.087 16.6588 122.082 17.2972 122 17.8131C121.868 18.6609 121.183 19.1155 120.417 19.2023C118.875 19.3811 117.322 19.4117 115.672 19.5139ZM117.102 18.2115C117.925 18.2115 118.727 18.2677 119.513 18.1962C120.182 18.1349 120.662 17.7314 120.632 16.9908C120.601 16.2757 120.049 15.9744 119.452 15.9131C118.691 15.8365 117.914 15.8927 117.102 15.8927C117.102 16.6843 117.102 17.4249 117.102 18.2115ZM117.062 12.5167C117.062 13.3287 117.036 13.9059 117.077 14.483C117.087 14.6567 117.276 14.9631 117.373 14.958C118.139 14.9325 118.936 14.9478 119.656 14.7333C119.968 14.6414 120.31 14.0336 120.31 13.6607C120.31 13.3441 119.876 12.8435 119.554 12.7618C118.793 12.578 117.986 12.5933 117.062 12.5167Z"
      fill="currentColor"
    />
    <path
      d="M131.158 15.479C131.148 13.0121 132.813 11.3318 135.275 11.3318C137.752 11.3267 139.468 12.9917 139.483 15.4126C139.499 17.8438 137.788 19.5548 135.341 19.565C132.849 19.5701 131.168 17.9306 131.158 15.479ZM132.644 15.4535C132.639 17.1032 133.727 18.2319 135.316 18.2166C136.909 18.2064 138.002 17.0725 137.997 15.4381C137.997 13.8038 136.899 12.6852 135.3 12.6801C133.712 12.6801 132.65 13.7833 132.644 15.4535Z"
      fill="currentColor"
    />
    <path
      d="M13.2476 22.8082C13.3089 21.9042 13.0842 20.801 13.5183 20.1472C13.9371 19.5241 15.0607 19.3913 15.8473 18.9878C16.4091 18.7018 16.8126 18.7682 17.2416 19.233C18.1048 20.1728 19.0241 21.0614 19.9026 21.9859C20.5104 22.6192 20.4542 22.941 19.6676 23.273C17.9056 24.0187 16.1384 24.7541 14.3712 25.4743C13.5847 25.7961 13.3191 25.602 13.3089 24.7286C13.2987 24.0902 13.3089 23.4517 13.3089 22.8133C13.2884 22.8133 13.268 22.8133 13.2476 22.8082Z"
      fill="currentColor"
    />
    <path
      d="M21.8077 12.435C19.208 12.435 19.2284 12.4248 18.1916 10.0753C17.8852 9.38583 17.9669 8.90573 18.5338 8.40009C19.443 7.5829 20.2755 6.6891 21.1591 5.84637C21.7464 5.28455 22.0937 5.35095 22.4002 6.08642C23.1357 7.8536 23.866 9.62078 24.581 11.3982C24.8926 12.1643 24.719 12.4197 23.8916 12.4401C23.1969 12.4452 22.5023 12.435 21.8077 12.435Z"
      fill="currentColor"
    />
    <path
      d="M186.349 13.1398C186.227 12.7618 186.114 12.435 185.987 12.0417C187.417 11.2654 188.877 10.9487 190.297 11.725C190.844 12.0213 191.411 12.8078 191.452 13.4054C191.595 15.3615 191.508 17.333 191.508 19.3453C191.16 19.3453 190.782 19.3453 190.359 19.3453C190.313 19.1155 190.272 18.8806 190.261 18.8499C189.327 19.0798 188.428 19.4168 187.509 19.4832C187.034 19.519 186.441 19.1666 186.043 18.8244C185.256 18.1553 185.149 16.7508 185.69 15.8263C186.405 14.6107 187.994 14.2839 190.18 14.9121C190.323 14.1562 190.277 13.3901 189.638 12.9508C188.596 12.2307 187.514 12.675 186.349 13.1398ZM188.382 18.38C188.923 18.0889 189.526 17.8744 189.996 17.4913C190.583 17.0061 190.389 16.1277 189.674 15.9285C189.153 15.7855 188.576 15.7344 188.04 15.7906C187.243 15.8774 186.809 16.4494 186.875 17.144C186.937 17.8284 187.463 18.2217 188.382 18.38Z"
      fill="currentColor"
    />
    <path
      d="M157.957 19.3504C157.604 19.3504 157.221 19.3504 156.797 19.3504C156.772 19.09 156.751 18.8346 156.721 18.5486C155.025 19.7284 153.585 19.851 152.568 18.9367C151.797 18.2421 151.588 16.7916 152.119 15.8467C152.819 14.6056 154.484 14.2787 156.706 14.9427C156.803 13.1194 156.072 11.8783 152.798 13.0836C152.681 12.7516 152.563 12.4248 152.431 12.0519C153.682 11.3369 154.949 11.1121 156.307 11.5054C157.272 11.7812 157.89 12.4605 157.931 13.4105C158.018 15.3717 157.957 17.3432 157.957 19.3504ZM155.076 15.7191C155.03 15.6527 154.984 15.5863 154.938 15.5199C154.438 15.8467 153.82 16.0817 153.483 16.5311C153.289 16.7865 153.411 17.6497 153.646 17.7722C154.152 18.048 154.816 18.0429 155.418 18.1145C155.567 18.1298 155.74 18.0276 155.878 17.9459C156.435 17.6292 156.997 17.2002 156.711 16.5209C156.563 16.1736 155.97 16 155.562 15.7803C155.434 15.7037 155.24 15.7344 155.076 15.7191Z"
      fill="currentColor"
    />
    <path
      d="M219.41 11.4441C219.41 14.1664 219.41 16.7201 219.41 19.33C219.006 19.33 218.603 19.33 218.072 19.33C218.072 18.5179 218.072 17.6752 218.072 16.8478C217.096 16.618 216.366 16.6793 215.921 17.5884C215.763 17.9101 215.451 18.1502 215.298 18.472C214.823 19.4883 214.011 19.519 212.99 19.2994C213.715 18.3392 214.41 17.4147 214.987 16.6435C214.496 16.0357 213.894 15.5505 213.654 14.9325C213.082 13.4564 213.909 11.9906 215.492 11.7097C216.739 11.4901 218.036 11.5258 219.41 11.4441ZM217.954 15.4075C217.954 14.5188 217.954 13.6914 217.954 12.8793C217.27 12.8793 216.682 12.8129 216.11 12.8946C215.436 12.9917 215.017 13.436 214.987 14.146C214.961 14.7742 215.492 15.336 216.207 15.3973C216.779 15.4484 217.362 15.4075 217.954 15.4075Z"
      fill="currentColor"
    />
    <path
      d="M65.5376 13.528C64.6081 13.528 63.842 13.528 63.0043 13.528C63.0043 12.8487 63.0043 12.2307 63.0043 11.5667C65.3435 11.5667 67.6572 11.5667 70.0118 11.5667C70.0118 12.2154 70.0118 12.818 70.0118 13.5075C69.1946 13.5075 68.4029 13.5075 67.5244 13.5075C67.5244 15.4943 67.5244 17.3994 67.5244 19.3454C66.8349 19.3454 66.2373 19.3454 65.5376 19.3454C65.5376 17.4454 65.5376 15.5454 65.5376 13.528Z"
      fill="currentColor"
    />
    <path
      d="M178.101 13.385C177.375 12.3737 177.396 12.0621 178.479 11.7557C179.449 11.485 180.501 11.3624 181.507 11.4033C182.416 11.439 183.249 11.9191 183.494 12.9202C183.75 13.9723 183.315 14.764 182.294 15.3054C183.305 15.6016 183.811 16.24 183.831 17.2053C183.852 18.14 183.234 18.9061 182.171 19.2687C180.425 19.8663 178.806 19.565 177.207 18.5333C177.483 18.1349 177.692 17.8233 177.784 17.6905C178.857 17.8897 179.786 18.0889 180.726 18.2166C181.007 18.2523 181.4 18.1706 181.589 17.9868C181.901 17.6854 182.309 17.2258 182.263 16.8887C182.217 16.5669 181.681 16.1889 181.293 16.0766C180.751 15.9182 180.144 15.9898 179.526 15.9591C179.526 15.5812 179.526 15.2645 179.526 14.8968C180.041 14.8559 180.537 14.8508 181.022 14.7742C181.589 14.6822 182.018 14.3911 182.039 13.7527C182.059 13.104 181.64 12.8385 181.073 12.6903C179.96 12.4043 179.01 12.818 178.101 13.385Z"
      fill="currentColor"
    />
    <path
      d="M162.308 12.864C161.282 12.864 160.398 12.864 159.469 12.864C159.469 12.4094 159.469 12.011 159.469 11.5616C161.818 11.5616 164.137 11.5616 166.497 11.5616C166.497 11.9957 166.497 12.3941 166.497 12.8793C165.552 12.8793 164.648 12.8793 163.672 12.8793C163.672 15.0602 163.672 17.1594 163.672 19.3249C163.202 19.3249 162.799 19.3249 162.308 19.3249C162.308 17.2309 162.308 15.1062 162.308 12.864Z"
      fill="currentColor"
    />
    <path
      d="M127.568 19.3607C127.021 19.3607 126.638 19.3607 126.143 19.3607C126.143 17.2155 126.143 15.0908 126.143 12.8946C125.157 12.8946 124.278 12.8946 123.333 12.8946C123.333 12.4247 123.333 12.0264 123.333 11.5718C125.657 11.5718 127.981 11.5718 130.356 11.5718C130.356 11.9906 130.356 12.389 130.356 12.8538C129.437 12.8538 128.553 12.8538 127.568 12.8538C127.568 15.0398 127.568 17.1645 127.568 19.3607Z"
      fill="currentColor"
    />
  </svg>
)

export default Logo
