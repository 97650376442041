import {
  AuthApi,
  CategoriesApi,
  ContentApi,
  InfoApi,
  ManufacturersApi,
  ProductsApi,
  PropertiesApi,
  SeederApi,
} from './generated/api';

interface ErrorDto {
  statusCode: string;
  message: string | string[];
}

export class ApiClientError extends Error {
  public status: number;
  public statusMessage: string;
  public url?: string;
  public connectionId?: string;
  public innerException?: string;

  constructor(response: Response, body: ErrorDto) {
    let message = `Статус ${response.status}: `;

    switch (response.status) {
      case 400:
        message += 'неверный запрос';
        break;
      case 401:
        localStorage.removeItem('token');
        message += 'не авторизован';
        break;
      case 403:
        message += 'запрещено';
        break;
      case 404:
        message += 'ресурс не найден';
        break;
      case 500:
        message += 'на сервере произошла ошибка';
        break;
      case 502:
      case 503:
      case 504:
        message += 'сервер недоступен';
        break;
      default:
        message += 'неизвестная ошибка';
        break;
    }
    if (response.headers.get('content-type')?.includes('application/json')) {
      try {
        if (body.message instanceof Array) {
          message += ` - ${body.message.join(', ')}`;
        } else {
          message += ` - ${body.message ?? ''}`;
        }
      } catch (e) {
        console.error(e);
      }
    }
    super(message);

    this.status = response.status;
    this.statusMessage = message;
    this.url = response.url;
  }
}

export class ApiClient {
  public productsApi: ProductsApi;
  public propertiesApi: PropertiesApi;
  public manufacturersApi: ManufacturersApi;
  public categoriesApi: CategoriesApi;
  public authApi: AuthApi;
  public infoApi: InfoApi;
  public seederApi: SeederApi;
  public contentApi: ContentApi;

  constructor() {
    const token = {
      accessToken: async () => {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
          throw new Error('Access token error');
        }
        return Promise.resolve(accessToken);
      },
    };

    this.productsApi = new ProductsApi(token);
    this.propertiesApi = new PropertiesApi(token);
    this.manufacturersApi = new ManufacturersApi(token);
    this.categoriesApi = new CategoriesApi(token);
    this.authApi = new AuthApi(token);
    this.infoApi = new InfoApi(token);
    this.seederApi = new SeederApi(token);
    this.contentApi = new ContentApi(token);
  }
}
