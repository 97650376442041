import React, {
  Fragment,
  FunctionComponent,
  ReactElement,
  useContext,
  useRef,
  useState,
} from 'react';
import { Button, Form, Pagination, Spinner, Table } from 'react-bootstrap';
import useSWR from 'swr';
import { debounce } from 'lodash';

import appContext from '../appContext';
import { useEffect } from 'react';
import { ProductDto, PropertyDto } from '../api/generated';
import { showErrorModal } from './shared/ErrorModal';

const ProductList: FunctionComponent = (props) => {
  const { apiClient } = useContext(appContext);

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(25);
  const [queryInput, setQueryInput] = useState<{ val: string }>();
  const [filter, setFilter] = useState<string>();

  const [selectedProduct, setSelectedProduct] = useState<ProductDto>();

  const debounceRef = useRef(debounce(setFilter, 1000));

  useEffect(
    function updateFilter() {
      debounceRef.current(queryInput?.val);
    },
    [queryInput]
  );

  useEffect(
    function resetPageOnFilterChange() {
      setPageIndex(1);
    },
    [filter]
  );

  const {
    data: paginatedProducts = { total: 0, results: [] },
    mutate: updateProductsList,
  } = useSWR(`page=${pageIndex}/size=${pageSize}/query=${filter}`, () =>
    apiClient.productsApi.getPaginatedProducts(
      'ASC',
      filter || undefined,
      pageSize,
      (pageIndex - 1) * pageSize
    )
  );

  const {
    data: productData,
    mutate: updateProductData,
    isValidating,
  } = useSWR(selectedProduct ? selectedProduct.id : null, (id) =>
    apiClient.productsApi.getProductWithData(id)
  );

  const handleDeleteProduct = (product: ProductDto) => {
    if (!window.confirm(`Удалить продукт: "${product.name}"`)) return;
    apiClient.productsApi
      .deleteProduct(product.id)
      .then(() => updateProductsList())
      .catch(showErrorModal);
  };

  const handleSelectProduct = (product: ProductDto) => {
    setSelectedProduct((x) => (x?.id === product.id ? undefined : product));
  };

  const handleDeleteProperty = (property: PropertyDto) => {
    if (!selectedProduct) return;
    if (!window.confirm(`Удалить хар-ку: "${property.name}"`)) return;
    apiClient.productsApi
      .deleteProductProperty(selectedProduct.id, property.id)
      .then(() => updateProductData())
      .catch(showErrorModal);
  };

  const renderPagination = (totalCount: number) => {
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.max(0, Math.min(pageIndex, totalPages));
    const lowPage = Math.max(currentPage - 5, 2);
    const hiPage = Math.min(currentPage + 5, totalPages);
    const pages: ReactElement[] = [];
    for (let i = lowPage; i < hiPage; i++) {
      pages.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => setPageIndex(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return (
      <Pagination size="sm" className="d-flex justify-content-center">
        <Pagination.Item
          active={currentPage === 1}
          onClick={() => setPageIndex(1)}
        >
          1
        </Pagination.Item>
        <Pagination.Prev
          onClick={() => setPageIndex((x) => (x <= 1 ? 1 : x - 1))}
        />
        {totalPages > 10 && <Pagination.Ellipsis />}
        {pages}
        {totalPages > 10 && <Pagination.Ellipsis />}
        <Pagination.Next
          onClick={() =>
            setPageIndex((x) => (x >= totalPages ? totalPages : x + 1))
          }
        />
        <Pagination.Item
          active={currentPage === totalPages}
          onClick={() => setPageIndex(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      </Pagination>
    );
  };

  const renderProductProperties = (properties: PropertyDto[]) => {
    return properties.map((property, i) => (
      <tr key={property.id} className="bg-info">
        <td align="right">{i + 1}</td>
        <td align="center">{property.name}</td>
        <td align="center">{property.value}</td>
        <td align="center">
          <Button
            size="sm"
            variant="danger"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteProperty(property);
            }}
          >
            X
          </Button>
        </td>
      </tr>
    ));
  };

  return (
    <div className="container-fluid h-100 overflow-hidden d-flex flex-column justify-content-center">
      <Form.Group controlId="filterInput" className="mb-2">
        <Form.Label>Поиск по названию</Form.Label>
        <Form.Control
          type="email"
          value={queryInput?.val || ''}
          onChange={(e) => setQueryInput({ val: e.currentTarget.value })}
        />
      </Form.Group>
      <div className="flex-grow-1 flex-shrink-1 overflow-auto mb-2">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Название</th>
              <th>Артикул</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {paginatedProducts.results.map((product, i) => (
              <Fragment key={product.id}>
                <tr
                  className="has-cursor-pointer"
                  onClick={() => handleSelectProduct(product)}
                >
                  <td>{i + 1 + (pageIndex - 1) * pageSize}</td>
                  <td>{product.name}</td>
                  <td>{product.code}</td>
                  <td align="center">
                    <Button
                      variant="danger"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteProduct(product);
                      }}
                    >
                      X
                    </Button>
                  </td>
                </tr>
                {selectedProduct?.id === product.id && isValidating && (
                  <tr>
                    <td align="center" colSpan={4}>
                      <Spinner animation="border" />
                    </td>
                  </tr>
                )}
                {!isValidating &&
                  productData?.id === product.id &&
                  renderProductProperties(productData.properties)}
              </Fragment>
            ))}
          </tbody>
        </Table>
      </div>
      <div>{renderPagination(paginatedProducts.total)}</div>
    </div>
  );
};

export default ProductList;
