import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import useSWR, { mutate } from 'swr';
import { toast } from 'react-toastify';

import { InfoDto } from '../api/generated';
import appContext from '../appContext';
import { showErrorModal } from './shared/ErrorModal';

const Info: FunctionComponent = (props) => {
  const { apiClient } = useContext(appContext);

  const [newInfo, setInfo] = useState<InfoDto>();

  const { data: info } = useSWR('info', () => apiClient.infoApi.get());

  useEffect(
    function updateInfo() {
      setInfo(info);
    },
    [info]
  );

  const handleSubmitForm = () => {
    if (!newInfo) return;
    apiClient.infoApi
      .update(newInfo)
      .then(() => mutate('info'))
      .then(() => toast('Данные успешно обновлены', { type: 'success' }))
      .catch(showErrorModal);
  };

  return (
    <div className="container-fluid h-100 overflow-auto p-2">
      {!newInfo ? (
        <Spinner animation="grow" />
      ) : (
        <Card>
          <Card.Body>
            <Card.Title className="mb-3">Контактная информация</Card.Title>
            <Form className="mb-5">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email адрес</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={newInfo.email}
                  onChange={(e) =>
                    setInfo({ ...newInfo, email: e.currentTarget.value })
                  }
                />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput2">
                <Form.Label>Телефон</Form.Label>
                <Form.Control
                  type="text"
                  value={newInfo.phone}
                  onChange={(e) =>
                    setInfo({ ...newInfo, phone: e.currentTarget.value })
                  }
                />
              </Form.Group>
            </Form>
            <Button onClick={handleSubmitForm}>Обновить</Button>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default Info;
