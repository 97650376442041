import React, { FunctionComponent, useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import useSWR, { mutate } from 'swr';
import { ManufacturerDto } from '../api/generated';
import appContext from '../appContext';
import { TextFileLoadType } from '../types';
import { showErrorModal } from './shared/ErrorModal';
import { showFileInput } from './shared/FileInput/FileInput';

const Manufacturer: FunctionComponent = (props) => {
  const { apiClient, getS3Url } = useContext(appContext);
  const { data: manufacturers = [] } = useSWR('manufacturers', () =>
    apiClient.manufacturersApi.getAll()
  );

  const handleAddImage = (id: string) => {
    showFileInput<TextFileLoadType>({
      accept: 'image/*',
      onLoadFiles: (result) =>
        apiClient.manufacturersApi
          .addImageToManufacturer(
            {
              name: result[0].fileName,
              base64Image: btoa(result[0].data),
            },
            id
          )
          .then(() => mutate('manufacturers'))
          .catch(showErrorModal),
      multiple: false,
      loadType: 'readAsBinaryString',
    });
  };

  const handleRemoveImage = (item: ManufacturerDto) => {
    if (!window.confirm(`Удалить изображение для производителя "${item.name}"`))
      return;
    apiClient.manufacturersApi
      .removeImageFromManufacturer(item.id)
      .then(() => mutate('manufacturers'))
      .catch(showErrorModal);
  };

  const handleChangeStatus = (id: string) => {
    apiClient.manufacturersApi
      .updateManufacturerStatus(id)
      .then(() => {
        mutate('manufacturers');
      })
      .catch(showErrorModal);
  };

  return (
    <div className="container-fluid h-100 overflow-auto">
      {manufacturers.map((item) => (
        <div
          className="row align-items-center mb-2 border-bottom pb-1"
          key={item.id}
        >
          <div className="col-4">{item.name}</div>
          <div className="col-3">
            <Button
              className="me-2"
              variant="outline-primary"
              onClick={() => handleAddImage(item.id)}
            >
              <i className="far fa-file-image"></i>
            </Button>
            {item.imageUrl && (
              <Button
                title="Удалить фото"
                variant="outline-secondary"
                onClick={() => handleRemoveImage(item)}
              >
                <i className="fas fa-trash-alt"></i>
              </Button>
            )}
          </div>
          <div className="col-4">
            {item.imageUrl && (
              <img
                className="object-fit_contain"
                width="180px"
                height="100px"
                src={getS3Url('img', item.imageUrl)}
                alt="manufacturer logo"
              />
            )}
          </div>
          <div className="col-1">
            <Form.Check
              type="checkbox"
              label="Активен"
              checked={item.isActive}
              onChange={() => handleChangeStatus(item.id)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Manufacturer;
