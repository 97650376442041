import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import appContext, { AppContext } from './appContext';
import Header from './Сomponents/shared/Header';
import { SWRConfig } from 'swr';
import { ApiClient } from './api/ApiClient';
import { GetS3Url } from './utils';
import Login from './Сomponents/shared/Login';
import { showErrorModal } from './Сomponents/shared/ErrorModal';
import Navigation from './Сomponents/Navigation';
interface Props {
  getS3Url: GetS3Url;
}

const App: FunctionComponent<Props> = (props) => {
  const context: AppContext = useMemo(() => {
    return {
      apiClient: new ApiClient(),
      getS3Url: props.getS3Url,
    };
  }, [props.getS3Url]);

  const [isAdmin, setIsAdmin] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(
    function checkToken() {
      context.apiClient.authApi
        .getProfile()
        .then(() => setIsAdmin(true))
        .catch((e) => {
          setIsAdmin(false);
          localStorage.removeItem('token');
        })
        .finally(() => setReady(true));
    },
    [context.apiClient.authApi]
  );

  return (
    <SWRConfig
      value={{
        onError: (e, key) => showErrorModal(e, key),
        shouldRetryOnError: false,
        revalidateOnFocus: false,
      }}
    >
      <appContext.Provider value={context}>
        <Header />
        <main className="container-fluid bg-secondary flex-grow-1 vh-100 vw-100 overflow-x-hidden d-flex justify-content-center align-items-center p-0">
          {!ready && (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
          {ready && !isAdmin && <Login />}
          {ready && isAdmin && <Navigation />}
        </main>
      </appContext.Provider>
      <ToastContainer />
    </SWRConfig>
  );
};

export default App;
